import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import Loder from "../Loder";
import { Carousel } from "react-responsive-carousel";
import Nodata from "../Nodata";
import { Rating } from "@mui/material";

const ExploreTask = () => {
  const [master, setMaster] = useState();
  const [isLoding, setIsLodin] = useState(false);
  const navigator = useNavigate();
  const { theme } = useContext(StakingApp);
  const [cagery, setCatgery] = useState(0);
  const [filter, setFilter] = useState("no");
  const [data, setData] = useState();
  const id = localStorage.getItem("id");
  const amster = () => {
    axiosInstance
      .get(API_URLS.Rewardlist)
      .then((res) => {
        setMaster(res.data.clientList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    amster();
  }, []);

  const GetProject = () => {
    setIsLodin(true);
    axiosInstance
      .get(API_URLS.projectlistbyclient + `/${id ? id : 0}/${cagery}/${filter}`)
      .then((res) => {
        setData(res.data.projectData);
        setIsLodin(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLodin(false);
      });
  };
  useEffect(() => {
    GetProject();
  }, [cagery, id, filter]);

  return (
    <>
      {isLoding ? (
        <Loder />
      ) : (
        <section
          className={`bg-${
            theme === "dark" ? "#000000" : theme
          } prflbg about innnerpage`}
        >
          <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
            <div>
              <Carousel
                autoPlay
                showStatus={false}
                showThumbs={false}
                showArrows={false}
              >
                {data?.map((i) => {
                  return (
                    <div
                      className={`lg:flex lg:p-10 p-2 items-center w-full lg:mt-0  bg-${
                        theme === "dark" ? "black" : "white"
                      } `}
                    >
                      <div className="hidden md:inline-block flex flex-col justify-center items-center w-[40%] bg-transparent">
                        <p
                          className={` font-bold lg:text-6xl w-full text-2xl ovrfl01 my-3 text-${
                            theme === "dark" ? "white" : "black"
                          } `}
                        >
                          {i.projectTitle}
                        </p>
                        <p
                          className={` font-bold my-5 lg:text-2xl w-[70%] ovrfl02 text-${
                            theme === "dark" ? "white" : "black"
                          } `}
                        >
                          {i.projectDescription}
                        </p>

                        <div class="btn3">
                          <p
                            onClick={() => {
                              navigator(`/TaskDtl`);
                              localStorage.setItem("id1", i.id);
                            }}
                          >
                            <button>Explore </button>
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-center items-center w-[100%] md:w-[100%]">
                        <img
                          src={i.projectBannerPath}
                          alt=""
                          className="rounded-xl"
                          style={{ height: "500px " }}
                        />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <div
            className={`bg-${theme === "dark" ? "#000000" : theme} container`}
          >
            <div className="row">
              <div className="col-lg-12">
                <div class="tab">
                  {/* <div>
                    <div className="row mb-4">
                      <div className="col-lg-8 col-md-8">
                        <div className="fl09">
                          <div className="search02">
                            <span className="bx bx-search-alt-2"></span>
                            <input placeholder="Search" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="fl09 text-center cursor-pointer fnt01">
                          <i className="bx bx-filter"></i> Show Trending
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="fl09 text-center cursor-pointer fnt01">
                          <i className="bx  bx-check-circle"></i> Available to
                          claim
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="flex justify-between w-full border-b my-3 py-3">
                    <div className="flex items-center gap-20 w-[30%]">
                      <p
                        className={`${
                          filter === "no"
                            ? "border-b-2 border-[#26C2C4] cursor-pointer"
                            : "cursor-pointer"
                        }`}
                        onClick={() => setFilter("no")}
                      >
                        All
                      </p>

                      <p
                        className={`${
                          filter === "yes"
                            ? "border-b-2 border-[#26C2C4] cursor-pointer"
                            : "cursor-pointer"
                        }`}
                        onClick={() => setFilter("yes")}
                      >
                        Watchlist
                      </p>
                    </div>

                    <div className="d-flex flex-wrap">
                      <span onClick={() => setCatgery(0)} className="fltbtn09">
                        All
                      </span>
                      {master?.map((i) => {
                        return (
                          <span
                            onClick={() => setCatgery(i.id)}
                            className="fltbtn09"
                          >
                            {i.name}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  {data?.length === 0 ? (
                    <div className="flex items-center justify-center">
                      {/* <Nodata /> */}
                      <p className="text-xl font-bold">No data</p>
                    </div>
                  ) : (
                    <div className="row">
                      {data?.map((item) => {
                        return (
                          <div className="col-lg-4 col-md-6 col-12 cursor-pointer">
                            <div className="my-4">
                              <div
                                onClick={() => {
                                  navigator(`/project-task`);
                                  localStorage.setItem("id1", item.id);
                                }}
                                className="proswitch bg-opacity-75 p-3 mb-3 rounded"
                              >
                                <div className="flex flex-wrap justify-start">
                                  {item.RewardName.map((i) => {
                                    return (
                                      <span className="pl01 ">{i.name}</span>
                                    );
                                  })}

                                  <span className="ml-auto pl-0 md:pl-5 inline-block md:block ">
                                    {/* <i className="bx bxs-layer"></i> */}
                                    <Rating
                                      name="read-only"
                                      value={item.rating}
                                      size="small"
                                      readOnly
                                    />
                                  </span>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-md-8 order-md-1 order-2">
                                    <h4 className="ovrfl01  mt-3 md:mt-0 font-bold">
                                      {item.projectTitle}
                                    </h4>
                                    <p className="ovrfl02 font-weight-normal mt-2 mb-3 small text-clip overflow-hidden">
                                      {item.projectDescription}
                                    </p>
                                    <div className="flex justify-between items-center w-full">
                                      {" "}
                                      <div className="d-flex lm089">
                                        <span>
                                          {item.clienttokenLogo === "N/A" ? (
                                            ""
                                          ) : (
                                            <img
                                              src={item.clienttokenLogo}
                                              class="img-fluid rounded-full object-contain"
                                            />
                                          )}
                                        </span>{" "}
                                        <span>{item.clientTitle}</span>{" "}
                                      </div>
                                      <div
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          navigator(`/TaskDtl`);
                                          localStorage.setItem("id1", item.id);
                                        }}
                                        className="d-flex lm089 bg-amber-500  flex items-center justify-center rounded-lg hover:bg-amber-600 hover:bg-opacity-50 px-2 py-1 text-white"
                                      >
                                        <span className="pl-2 hover:text-white">
                                          Show Detail
                                        </span>{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 order-md-2 order-1">
                                    <div className="expimg">
                                      <img
                                        src={item.projectBannerPath}
                                        className="img-fluid "
                                        alt="Moc"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ExploreTask;
