import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../../Img/nodata.json";

export default function Nodata() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="">
      <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
    </div>
  );
}
