import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import { Box, CircularProgress } from "@mui/material";

export default function Signup() {
  const { theme } = useContext(StakingApp);
  const { address } = useAccount();
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [Newpass, setNewpass] = useState("");
  const [Confirmpass, setConfirmpass] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoding, setIsLoding] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!firstname) {
      errors.firstname = "First name is required";
      valid = false;
    }

    if (!lastname) {
      errors.lastname = "Last name is required";
      valid = false;
    }

    if (!email) {
      errors.email = "Email is required";
      valid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Invalid email address";
      valid = false;
    }

    if (!country) {
      errors.country = "Country is required";
      valid = false;
    }

    if (!Newpass) {
      errors.Newpass = "Password is required";
      valid = false;
    } else if (Newpass.length < 6) {
      errors.Newpass = "Password must be at least 6 characters";
      valid = false;
    }

    if (!Confirmpass) {
      errors.Confirmpass = "Password confirmation is required";
      valid = false;
    } else if (Confirmpass !== Newpass) {
      errors.Confirmpass = "Passwords do not match";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const UserSignup = () => {
    if (!validateForm()) {
      return;
    }
    setIsLoding(true);
    axiosInstance
      .post(API_URLS.createnewuser, {
        firstname,
        lastname,
        Newpass,
        Confirmpass,
        country,
        email,
        address: "1",
      })
      .then((res) => {
        setFirstName("");
        setNewpass("");
        setLastName("");
        setConfirmpass("");
        setCountry("");
        setEmail("");
        toast.success(res.data.message);
        navigate("/login");
        setIsLoding(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoding(false);
      });
  };

  return (
    <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
      <main className="regbox" id="main">
        <section className="headermain">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 offset-lg-1">
                <h3 className="my-2 font-bold text-xl">Create your account</h3>
                <hr />
                <div className="regform row">
                  <div className="col-lg-6 col-md-6">
                    <div className="cover1">
                      <label className="my-1">First name</label>
                      <input
                        type="text"
                        className="bgcolors form-control"
                        placeholder="First name"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstname}
                      />
                      {errors.firstname && (
                        <div className="error text-red-500">
                          {errors.firstname}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="cover1">
                      <label className="my-1">Last name</label>
                      <input
                        type="text"
                        className="bgcolors form-control"
                        placeholder="Last name"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastname}
                      />
                      {errors.lastname && (
                        <div className="error text-red-500">
                          {errors.lastname}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="cover1">
                      <label className="my-1">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter country"
                        onChange={(e) => setCountry(e.target.value)}
                        value={country}
                      />
                      {errors.country && (
                        <div className="error text-red-500">
                          {errors.country}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="cover1">
                      <label className="my-1">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      {errors.email && (
                        <div className="error text-red-500">{errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="cover1">
                      <label className="my-1">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={(e) => setNewpass(e.target.value)}
                        value={Newpass}
                      />
                      {errors.Newpass && (
                        <div className="error text-red-500">
                          {errors.Newpass}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="cover1">
                      <label className="my-1">Retype Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Retype Password"
                        onChange={(e) => setConfirmpass(e.target.value)}
                        value={Confirmpass}
                      />
                      {errors.Confirmpass && (
                        <div className="error text-red-500">
                          {errors.Confirmpass}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <p className="small">
                  <input type="checkbox" /> Yes, I have read and I understand
                  the Mystery of Crypto's <a href="#">Terms & Conditions</a>.
                </p>
                <div className="w-100 btn1">
                  {isLoding ? (
                    <a href="#" className="w-100 d-block">
                      <button className="w-100 d-block">
                        <CircularProgress />
                      </button>
                    </a>
                  ) : (
                    <a href="#" className="w-100 d-block">
                      <button
                        onClick={() => UserSignup()}
                        className="w-100 d-block"
                      >
                        Create Account
                      </button>
                    </a>
                  )}
                </div>
                <div className="text-center">
                  Already a member? <a href="/login">Sign In</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
