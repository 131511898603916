import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import LanguageIcon from "@mui/icons-material/Language";
import Loder from "../Loder";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import { Rating } from "@mui/material";
import { toast } from "react-toastify";
const TaskDtl = () => {
  const id = localStorage.getItem("id1");
  const [isLoding, setIsLodin] = useState(false);
  const [data, setData] = useState();
  const { theme } = useContext(StakingApp);

  const handleChange = (newValue) => {
    const formData = new FormData();
    formData.append("projectid", id);
    formData.append("rate", newValue);
    axiosInstance
      .post(API_URLS.ratingforproject, formData)
      .then((res) => {
        toast.success(res.data.message);
        GetProject();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const Fallow = () => {
    axiosInstance
      .post(API_URLS.projectfollowingbyuser + `/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        GetProject();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetProject = () => {
    setIsLodin(true);
    axiosInstance
      .get(API_URLS.taskgrouplistbyproject + `/${id}`)
      .then((res) => {
        setData(res.data);

        setIsLodin(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLodin(false);
      });
  };
  useEffect(() => {
    GetProject();
  }, []);
  return (
    <>
      {isLoding ? (
        <Loder />
      ) : (
        <div>
          <div className=" tsbbg01 flex h-[500px] w-full">
            <img
              className="w-full"
              src={data?.projectdata?.clientBanner}
              alt=""
            />
          </div>
          <section
            className={`bg-${
              theme === "dark" ? "#000000" : theme
            } prflbg about `}
          >
            <div className="container">
              <div className="flex flex-wrap w-full justify-between">
                <div className="col-md-10">
                  <div className="flex flex-wrap gap-2 md:gap-5 items-center">
                    <img
                      src={data?.projectdata?.clientLogo}
                      alt=""
                      className="h-32 w-32 rounded-full"
                    />
                    <div>
                      <div className="flex flex-wrap gap-5">
                        <p>{data?.projectdata?.clientTitle}</p>
                        <a
                          href={data?.projectdata?.clientsocaillink}
                          target="_blank"
                        >
                          <LanguageIcon />
                        </a>
                      </div>
                      <div className="section-title">
                        <h2>{data?.projectdata?.projectTitle}</h2>
                      </div>
                      <p className="ovrfl02 font-weight-normal my-3 small  text-ellipsis overflow-hidden">
                        {data?.projectdata?.projectDescription}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="flex flex-col gap-5">
                    <div className="flex gap-2 col-md-8">
                      {data?.projectdata.clienttokenLogo === "N/A" ? (
                        ""
                      ) : (
                        <img
                          src={data?.projectdata.clienttokenLogo}
                          alt={data?.projectdata.clienttokenLogo}
                          className="h-5 w-5"
                        />
                      )}
                      <span className="flex flex-col">
                        <p className="text-sm">Token Name</p>
                        <p>{data?.projectdata.clientTokenname}</p>
                      </span>
                    </div>
                    <div className="flex items-center gap-5">
                      <div className="flex flex-col justify-center items-center bg-gray-200 bg-opacity-20 px-2 py-1 rounded-lg cursor-pointer">
                        <p className="text-sm">Rate Project</p>
                        {token ? (
                          <Rating
                            name="simple-controlled"
                            value={data?.projectdata.rating}
                            size="small"
                            onChange={handleChange}
                          />
                        ) : (
                          <div onClick={() => navigate("/login")}>
                            <Rating
                              name="simple-controlled"
                              value={data?.projectdata.rating}
                              size="small"
                            />
                          </div>
                        )}
                      </div>
                      {data?.projectdata.follow === "1" ? (
                        <p
                          onClick={() => Fallow(data?.projectdata.id)}
                          className="items-center py-1 px-3 cursor-pointer text-white font-bold small  rounded-md bg-teal-500 "
                        >
                          Following
                        </p>
                      ) : (
                        <>
                          {token ? (
                            <p
                              onClick={() => Fallow(data?.projectdata.id)}
                              className="items-center border py-1 px-3 cursor-pointer bg-[#19CDD0] rounded-xl hover:text-black"
                            >
                              Follow +
                            </p>
                          ) : (
                            <p
                              onClick={() => navigate("/login")}
                              className="items-center border py-1 px-3 cursor-pointer bg-[#19CDD0] rounded-xl hover:text-black"
                            >
                              Follow +
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="seprator"></div>
            </div>
            <div className="container mt-2">
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <div className="view-options"></div>
                    <div id="products">
                      <div className=" mt-4 row item">
                        {data?.TaskgroupData?.map((i) => {
                          return (
                            <div className="item col-xs-4 col-lg-4 cursor-pointer">
                              <a
                                onClick={() => {
                                  navigate(`/coindetail`);
                                  localStorage.setItem("id2", i.id);
                                }}
                              >
                                <div className="proswitch bg-opacity-75 p-3 mb-3 rounded">
                                  <div className="d-flex justify-content-start">
                                    <span className="pl01">
                                      {i.TotalPoint} Points
                                    </span>

                                    <span className="ml-auto  pl01">
                                      {(new Date(i.endtime) -
                                        new Date(i.start)) /
                                        (1000 * 60 * 60 * 24)}{" "}
                                      Days
                                    </span>
                                  </div>
                                  <div className="row mt-4">
                                    <div
                                      onClick={() => navigate("/coindetail")}
                                      className="col-md-8 order-md-1 order-2"
                                    >
                                      <h4 className="ovrfl01 font-bold mt-3 md:mt-0">
                                        {i.taskgrouptitle}
                                      </h4>

                                      <p className="ovrfl02 font-weight-normal my-3 small  text-ellipsis overflow-hidden ">
                                        {i.taskgroupdescribtion}
                                      </p>
                                      <div className="d-flex lm089">
                                        <span>
                                          {data?.projectdata
                                            ?.clienttokenLogo === "N/A" ? (
                                            ""
                                          ) : (
                                            <img
                                              src={
                                                data?.projectdata
                                                  ?.clienttokenLogo
                                              }
                                              class="img-fluid"
                                              alt="MoC"
                                            />
                                          )}
                                        </span>{" "}
                                        <span>
                                          {data?.projectdata?.clientTitle}
                                        </span>{" "}
                                      </div>
                                    </div>
                                    <div className="col-md-4 order-md-2 order-1">
                                      <div className="expimg">
                                        <img
                                          src={i.imagePath}
                                          className="img-fluid"
                                          alt="Moc"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default TaskDtl;
