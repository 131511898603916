import { Dialog, styled } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { recoverMessageAddress } from "viem";
import { useAccount, useSignMessage } from "wagmi";
import image from "../Img/meta.png";
import axiosInstance from "../Config/axios";
import { API_URLS } from "../Config/apiUrls";
import axiosInstance1 from "../Config/axiosuser";

export default function Sign() {
  const [open, setOpen] = useState(false);
  const recoveredAddress = React.useRef("");
  const refral = localStorage.getItem("refral");
  const { address } = useAccount();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [nonce, setNonce] = useState();
  const message = nonce;
  const { data: signMessageData, signMessage } = useSignMessage();
  const path = localStorage.getItem("path");

  const Rating1 = () => {
    axiosInstance1
      .get(API_URLS.getNonce)
      .then((res) => {
        setNonce(res.data.nonce);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    address && setOpen(true);
    address && Rating1();
  }, [address]);

  const UserSignup = () => {
    axios({
      url: `https://restapi.aquaeal.in/UserApi/walletlogin/${refral}`,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": "MOC!#321",
      },
      data: { address: address, message: nonce, signature: signMessageData },
    })
      .then((res) => {
        toast.success(res.data.message);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userID", res.data.userdata.id);
        localStorage.setItem(
          "twitterLinkstatus",
          res.data.userdata.twitterLinkstatus
        );

        goToHem();
      })
      .catch((err) => {
        console.log(err);

        toast.warn(err.response.data.message);
      });
  };
  const User = () => {
    axios({
      url: `https://restapi.aquaeal.in/UserApi/UpdateWallectAddress`,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": "MOC!#321",
        "Auth-Token": `${token}`,
      },
      data: { address: address, message: nonce, signature: signMessageData },
    })
      .then((res) => {
        toast.success(res.data.message);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userID", res.data.userdata.id);
        localStorage.setItem(
          "twitterLinkstatus",
          res.data.userdata.twitterLinkstatus
        );
        setOpen(false);
        goToHem();
        // navigate(`/home`);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        toast.warn(err.response.data.message);
      });
  };

  const goToHem = () => {
    if (path) {
      navigate(`/${JSON.parse(path)}`);
      console.log("hello");
    } else {
      navigate("/home");
      console.log("hey");
    }
  };

  useEffect(() => {
    signMessageData && token ? User() : UserSignup();
  }, [signMessageData]);

  useEffect(() => {
    token && setOpen(false);
  }, [token]);

  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          className:
            "!w-[25%] flex gap-3 flex-col  !rounded-2xl !text-shadow-none!",
        }}
      >
        <div className="flex justify-center items-center my-6">
          <div className="text-center">
            <img
              src={image}
              alt="MoC"
              style={{ height: "90px", margin: "0 auto 10px" }}
            />
            <div class="text-white ">
              <p
                onClick={() => signMessage({ message })}
                className="btn btn-outline-dark px-5"
              >
                Sign In
              </p>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
