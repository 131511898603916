import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideDrawer from "../SideDrawer";
import { StakingApp } from "../../UserDasbord/StateMengmanet";

import { API_URLS } from "../../Config/apiUrls";
import axiosInstance1 from "../../Config/axiosuser";

const Userpro = () => {
  const { theme } = useContext(StakingApp);
  const [page, setPage] = useState("dashboard");
  const [data, setData] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    !token && navigate("/login");
  }, []);
  const AddProject = () => {
    axiosInstance1
      .get(API_URLS.getDashboard)
      .then((res) => {
        setData(res.data.userdata);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    AddProject();
  }, []);

  return (
    <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
      <main id="main">
        <section className="innnerpage mt-5">
          <div className="container">
            <div className="section-title">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <a>
                    <h4>
                      <i className="bx bxs-user-circle"></i> My Account
                    </h4>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main">
                  <div className="tab">
                    <div className="tab-menu gap-5">
                      <button
                        className={`tab-menu-link ${
                          page === "dashboard" ? "is-active" : ""
                        }`}
                        onClick={() => setPage("dashboard")}
                      >
                        <h4 className="d-block text-center mb-0">
                          <i className="bx bxs-user-check"></i>
                        </h4>
                        <span data-title="item-1">My Earning</span>
                      </button>

                      <button
                        className={`tab-menu-link ${
                          page === "socail" ? "is-active" : ""
                        }`}
                        onClick={() => setPage("socail")}
                      >
                        <h4 className="d-block text-center mb-0">
                          <i className="bx bx-analyse"></i>
                        </h4>
                        <span data-title="item-2">Social Accounts</span>
                      </button>
                      <button
                        className={`tab-menu-link ${
                          page === "rewards" ? "is-active" : ""
                        }`}
                        onClick={() => setPage("rewards")}
                      >
                        <h4 className="d-block text-center mb-0">
                          <i className="bx bx-trophy"></i>
                        </h4>
                        <span data-title="item-3">Task Rewards</span>
                      </button>
                    </div>
                    <div className="tab-bar">
                      <div className="row justify-content-center">
                        <div className="col-lg-12">
                          {page === "dashboard" && (
                            <div class="container">
                              <div class="row ">
                                <div class="col-lg-4 col-md-6 my-5 ">
                                  <div class="usbox mesur">
                                    <span class="icon04">
                                      <img
                                        src="assets/img/earning.png"
                                        alt=""
                                      />
                                    </span>
                                    <p>Total Earnings</p>
                                    <h4>$200</h4>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-6 my-5">
                                  <div class="usbox mesur">
                                    <span class="icon04">
                                      <img src="assets/img/task.png" alt="" />
                                    </span>
                                    <p>Total Tasks</p>
                                    <h4>200</h4>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-6 my-5">
                                  <div class="usbox mesur">
                                    <span class="icon04">
                                      <img src="assets/img/task.png" alt="" />
                                    </span>
                                    <p>Pending Tasks</p>
                                    <h4>200</h4>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-6 my-5">
                                  <div class="usbox mesur">
                                    <span class="icon04">
                                      <img src="assets/img/task.png" alt="" />
                                    </span>
                                    <p>Completed Tasks</p>
                                    <h4>200</h4>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-6 my-5">
                                  <div class="usbox mesur">
                                    <span class="icon04">
                                      <img
                                        src="assets/img/staking.png"
                                        alt=""
                                      />
                                    </span>
                                    <p>Staking</p>
                                    <h4>$200</h4>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-6 my-5">
                                  <div class="usbox mesur">
                                    <span class="icon04">
                                      <img
                                        src="assets/img/staking.png"
                                        alt=""
                                      />
                                    </span>
                                    <p>Rewards</p>
                                    <h4>$200</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {page === "socail" && (
                            <div>
                              <div className="texts">
                                <h4 className="title">Social Media Accounts</h4>

                                <div className="my-3 d-flex flex-column position-relative">
                                  <span className="text-muted">
                                    <i className="bx bxl-twitter"></i> Twitter
                                    Account
                                  </span>
                                  <p>https://twitter.com/account/access</p>
                                  <span className="editpro">
                                    <a>
                                      <i className="bx bx-edit-alt"></i>
                                    </a>
                                  </span>
                                </div>
                                <div className="my-3 d-flex flex-column position-relative">
                                  <span className="text-muted">
                                    <i className="bx bxl-instagram"></i>{" "}
                                    Instagram:
                                  </span>
                                  <p>
                                    https://www.instagram.com/mysteryofcrypto/
                                  </p>
                                  <span className="editpro">
                                    <a>
                                      <i className="bx bx-edit-alt"></i>
                                    </a>
                                  </span>
                                </div>
                                <div className="my-3 d-flex flex-column position-relative">
                                  <span className="text-muted">
                                    <i className="bx bxl-telegram"></i>{" "}
                                    Telegram:
                                  </span>
                                  <p>https://t.me/Mysteryofcrypto</p>
                                  <span className="editpro">
                                    <a>
                                      <i className="bx bx-edit-alt"></i>
                                    </a>
                                  </span>
                                </div>
                                <div className="my-3 d-flex flex-column position-relative">
                                  <span className="text-muted">
                                    <i className="bx bxl-youtube"></i> Youtube:
                                  </span>
                                  <p>
                                    https://dunitech.ae/demo/mocdemo1/Www.youtube.com/c/mysteryofcrypto
                                  </p>
                                  <span className="editpro">
                                    <a>
                                      <i className="bx bx-edit-alt"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          {page === "rewards" && (
                            <div>
                              <div className="texts">
                                <h4 className="title">Task Rewards</h4>
                                <div class="table-responsive">
                                  <table class="table border-0">
                                    <thead style={{ color: "#e9e9e9" }}>
                                      <tr>
                                        <th>Sr.</th>
                                        <th>Rewards ($)</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      class="small"
                                      style={{ color: "#829494" }}
                                    >
                                      <tr>
                                        <td>1</td>
                                        <td>223</td>
                                        <td>08-05-2024</td>
                                        <td>Success</td>
                                      </tr>
                                      <tr>
                                        <td>2</td>
                                        <td>223</td>
                                        <td>08-05-2024</td>
                                        <td>Pending</td>
                                      </tr>
                                      <tr>
                                        <td>3</td>
                                        <td>223</td>
                                        <td>08-05-2024</td>
                                        <td>Pending</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Userpro;
