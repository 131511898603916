import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

const Layout = ({ component }) => {
  return (
    <>
      <Header />
      <div>{component}</div>
      <Footer />
    </>
  );
};

export default Layout;
