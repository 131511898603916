import React, { useContext, useEffect, useState } from "react";
import SideDrawer from "../../Pages/SideDrawer";
import { StakingApp } from "../../UserDasbord/StateMengmanet";

export default function Forgotpassword() {
  const { theme } = useContext(StakingApp);
  return (
    <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
      <main class="regbox" id="main">
        <section class="headermain">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 offset-lg-1">
                <h3 className="my-3 font-bold text-xl">Change Password ?</h3>
                {/* <p>
                  Don't worry! Send us your registered email and we'll send you
                  a reset.
                </p> */}

                <div class="regform row">
                  <div class="col-lg-12 col-md-12">
                    <div class="cover1">
                      <input
                        type="password"
                        class="bgcolors form-control"
                        placeholder="Enter old password
"
                      />
                    </div>
                    <div class="cover1">
                      <input
                        type="password"
                        class="bgcolors form-control"
                        placeholder="Enter new password"
                      />
                    </div>
                    <div class="cover1">
                      <input
                        type="password"
                        class="bgcolors form-control"
                        placeholder="Enter confirm password


"
                      />
                    </div>
                  </div>
                </div>

                <div class="w-100 btn1">
                  <a href="#" class="w-100 d-block">
                    <button class="w-100 d-block">Change Password</button>
                  </a>
                </div>

                <div class="text-center">
                  New member? <a href="/signup">Sign Up</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
