import { Drawer } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import infulancer from "../../Img/lucky.png";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import axiosInstance1 from "../../Config/axiosuser";
import { API_URLS } from "../../Config/apiUrls";
import PersonIcon from "@mui/icons-material/Person";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GroupIcon from "@mui/icons-material/Group";
import { toast } from "react-toastify";
import FileCopyIcon from "@mui/icons-material/FileCopy";

export default function SideDrawer() {
  const { setOpen1, open1 } = useContext(StakingApp);
  const { open } = useWeb3Modal();
  const [copy, setcopy] = useState();
  const { address } = useAccount();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const token = localStorage.getItem("token");
  const onCopyLink = (id) => {
    navigator?.clipboard?.writeText(id);
    toast.success(`Copy ${id}`);
    setcopy(id);
  };

  const refalarlink =
    "https://website.aquaeal.in/" + `?Referral=` + `${data?.yourReferralcode}`;

  const Logout = () => {
    localStorage.clear();
    handleDrawerClose();
    navigate("/home");
  };

  const Logout1 = () => {
    localStorage.clear();
  };

  const AddProject = () => {
    axiosInstance1
      .get(API_URLS.getDashboard)
      .then((res) => {
        setData(res.data.userdata);
        localStorage.setItem("x", res.data.userdata.twitterLinkstatus);
        localStorage.setItem("you", res.data.userdata.youtubelinkStatus);
        localStorage.setItem("dis", res.data.userdata.DiscordLinkStatus);
        localStorage.setItem("teli", res.data.userdata.TelegramLinkStatus);
      })
      .catch((err) => {
        Logout1();
      });
  };
  useEffect(() => {
    token && AddProject();
  });

  const handleDrawerOpen = () => {
    setOpen1(true);
  };

  const handleDrawerClose = () => {
    setOpen1(false);
  };

  const Go = () => {
    navigate("/profile");
    localStorage.setItem("tab", 1);
  };

  return (
    <div>
      {token ? (
        <div
          onClick={handleDrawerOpen}
          className="flex justify-center items-center gap-2  bg-teal-500 p-1 px-3 rounded-lg cursor-pointer"
        >
          {data?.imagePath ? (
            <img
              src={data?.imagePath}
              alt=""
              className="rounded-full h-10 w-10"
            />
          ) : (
            <div className="flex justify-center items-center rounded-full h-10 w-16 ">
              <PersonIcon className="!text-[#fff]" />
            </div>
          )}
          <div className="flex flex-col justify-center items-center">
            <p className="text-sm btncolor">EARNING</p>
            <p className="text-sm font-bold btncolor">{data?.totalpoint}</p>
          </div>
        </div>
      ) : (
        <div className="flex gap-5">
          <li>
            <a href="/login" className="btn btn-outline-info font-weight-bold">
              Sign
            </a>
          </li>
          {/* <li>
            <a href="/signup" className="btn btn-outline-info font-weight-bold">
              Register
            </a>
          </li> */}
        </div>
      )}
      <Drawer
        PaperProps={{
          className: "!w-[270px] py-4 px-2 !bg-[#29303d] !bg-opacity-90 ",
        }}
        anchor="right"
        open={open1}
        onClose={handleDrawerClose}
      >
        <div className="flex flex-col justify-center items-center w-full   ">
          <div className="flex justify-between items-center w-full">
            <p className="font-bold text-white text-sm">HELLO !</p>
            <CloseIcon
              onClick={() => handleDrawerClose()}
              className="text-white cursor-pointer"
            />
          </div>
          <div
            onClick={() => {
              Go();
              handleDrawerClose();
            }}
            className="flex w-full justify-center items-center my-4 cursor-pointer hover:bg-black hover:bg-opacity-20 hover:rounded-xl py-1 px-1"
          >
            {data?.imagePath ? (
              <div className="flex justify-center items-center rounded-full  h-12 w-16 ">
                <img src={data?.imagePath} alt="" className="rounded-full" />
              </div>
            ) : (
              <div className="flex justify-center items-center rounded-full bg-gray-100 h-12 w-16 ">
                <PersonIcon className="!text-[#26C2C4]" />
              </div>
            )}

            <span className="flex flex-col justify-between items-center w-full">
              <p className="text-white font-bold small2">Account</p>
              <p className="text-sm text-white">
                {data?.email ? data?.email?.slice(0, 5) : "---"}
                {data?.email ? "..." : ""}@{data?.email?.split("@")?.[1]}
              </p>
            </span>
            <span>
              <KeyboardArrowRightIcon className="text-white !text-3xl" />
            </span>
          </div>
          {data?.totalpoint !== "0" ? (
            <div className=" flex justify-between items-center w-full  cursor-pointer rounded-lg text-center my-5 py-2 border px-2 border-gray-500  ">
              <p className="font-bold text-white small">Earn Point's</p>
              <p className=" text-white pr-3 small">{data?.totalpoint}</p>
            </div>
          ) : (
            ""
          )}

          {data?.address ? (
            <div className="flex truncate w-full justify-between items-center gap-3 bg-black py-2 rounded-xl text-sm bg-opacity-50 text-white px-3 cursor-pointer">
              <p className="text-clip font-bold small">
                {address?.slice(0, 20)}...
              </p>
              {copy === address ? (
                <FileCopyIcon />
              ) : (
                <ContentCopyIcon onClick={() => onCopyLink(address)} />
              )}
            </div>
          ) : (
            <div className="flex w-full justify-between items-center bg-black py-2 rounded-xl text-sm bg-opacity-50 text-red-500 px-3">
              <p>
                <ErrorOutlineIcon />
              </p>
              <p>Wallet is missing</p>
              <p
                onClick={() => {
                  open();
                  handleDrawerClose();
                }}
                className="font-bold cursor-pointer"
              >
                Add
              </p>
            </div>
          )}
          {/* {data?.twitterLinkstatus === "0" ? (
            <a
              href={`https://restapi.aquaeal.in/TwitterAuth/login?UserToken=${token}`}
              target="_blank"
              className=" mt-5 cursor-pointer bg-black bg-opacity-20 rounded-lg text-center w-full py-2 hover:bg-black hover:bg-opacity-50  "
            >
              Connect With X
            </a>
          ) : (
            <a className=" mt-5 cursor-pointer bg-black bg-opacity-20 rounded-lg text-center w-full py-2 hover:bg-black hover:bg-opacity-50  ">
              Connected With X
            </a>
          )} */}

          <div className="flex flex-col mt-5 cursor-pointer bg-black bg-opacity-20 rounded-lg text-center w-full py-2 hover:bg-black hover:bg-opacity-50  ">
            <div className="flex justify-between items-center px-3">
              <GroupIcon className="text-white" />
              <p className="text-white small2">My Referral Code</p>
            </div>
            <div className="flex justify-between items-center mt-3 px-3 gap-2 ">
              <p className="bg-gray-600 p-1 text-sm rounded-lg text-white truncate">
                {refalarlink}
              </p>
              {copy === refalarlink ? (
                <FileCopyIcon className="text-white small2" />
              ) : (
                <ContentCopyIcon
                  className="text-white small2"
                  onClick={() => onCopyLink(refalarlink)}
                />
              )}
            </div>
          </div>

          {/* <div
            onClick={() => {
              navigate("/userpro");
              handleDrawerClose();
            }}
            className="  mt-5 cursor-pointer bg-black bg-opacity-20 rounded-lg text-center w-full py-2 hover:bg-black hover:bg-opacity-50 "
          >
            <p className="font-bold text-white">My Earning</p>
          </div> */}
          <div
            onClick={() => {
              navigate("/home");
              handleDrawerClose();
            }}
            className="  cursor-pointer bg-emerald-500 bg-opacity-20 rounded-lg text-center w-full py-2 hover:bg-black hover:bg-opacity-50 absolute inset-x-0 bottom-3  "
          >
            <p onClick={() => Logout()} className="font-bold text-white ">
              Logout
            </p>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
