import TelegramLoginButton from "react-telegram-login";

export default function TelegramLogin() {
  const onAuth = (ctx) => {
    localStorage.setItem("ctx", ctx);
  };
  return (
    <div>
      {" "}
      <div className="bg-white text-5xl text-white my-52">
        Random text
        <TelegramLoginButton botName="moc_crypto_bot" dataOnAuth={onAuth} />
      </div>
    </div>
  );
}
