"use Server";
import React, { useState, createContext } from "react";

export const StakingApp = createContext();

export const Staking = ({ children }) => {
  const [clintid, setClinteid] = useState();
  const [open, setOpen] = useState(false);
  const [yopen, setYOpen] = useState(false);
  const [topen, setTOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [id, setId] = useState();
  const [theme, setTheme] = useState(getColorPreference());
  const [ddis, setDOpen] = useState(false);

  function getColorPreference() {
    return (
      localStorage.getItem("theme-preference") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
    );
  }

  return (
    <StakingApp.Provider
      value={{
        open1,
        setOpen1,
        id,
        setId,
        theme,
        setTheme,
        open,
        setOpen,
        clintid,
        setClinteid,
        yopen,
        setYOpen,
        setDOpen,
        ddis,
        topen,
        setTOpen,
      }}
    >
      {children}
    </StakingApp.Provider>
  );
};
