import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import { Carousel } from "react-responsive-carousel";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
// import HeaderCarousel from "../../HeaderCarousel";

export default function Home() {
  const [data, setData] = useState();
  const { theme } = useContext(StakingApp);
  const navigate = useNavigate();
  const AddProject = () => {
    axiosInstance
      .get(API_URLS.ClientList + "/0/all")
      .then((res) => {
        setData(res.data.clientList);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    AddProject();
  }, []);
  return (
    <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
      <div>
        <Carousel
          autoPlay
          showStatus={false}
          showThumbs={false}
          showArrows={false}
        >
          {data?.map((i) => {
            return (
              <div
                className={`lg:flex lg:p-10 p-4 items-center w-full lg:mt-0 bg-${
                  theme === "dark" ? "black" : "white"
                } `}
              >
                <div className="hidden md:inline-block flex flex-col justify-center items-center w-[40%] bg-transparent"     >
                  <p
                    className={` font-bold lg:text-6xl w-full text-2xl my-3 ovrfl01 text-${
                      theme === "dark" ? "white" : "black"
                    } `}
                  >
                    {i.nametitle}
                  </p>
                  <p
                    className={` font-bold my-5 lg:text-2xl w-[70%] ovrfl02 text-${
                      theme === "dark" ? "white" : "black"
                    } `}
                  >
                    {i.clientDescribtion}
                  </p>

                  <div class="btn3">
                    <p
                      onClick={() => {
                        navigate(`/ExploreTask`);

                        localStorage.setItem("id", i.id);
                      }}
                    >
                      <button>Explore </button>
                    </p>
                  </div>
                </div>
                <div className="flex justify-center items-center w-[100%] md:w-[60%]">
                  <img
                    src={i.bannerLogoPath}
                    alt=""
                    className="rounded-xl h500"    />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
