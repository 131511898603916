import React, { useContext, useEffect, useState } from "react";
import { StakingApp } from "../UserDasbord/StateMengmanet";

export default function Header() {
  const { theme, setTheme } = useContext(StakingApp);

  function setPreference() {
    localStorage.setItem("theme-preference", theme);
    reflectPreference();
  }

  function reflectPreference() {
    document.documentElement.setAttribute("data-theme", theme);
    document.getElementById("theme-toggle")?.setAttribute("aria-label", theme);
  }

  useEffect(() => {
    reflectPreference();
    setPreference();

    const toggleTheme = () => {
      const newTheme = theme === "light" ? "dark" : "light";
      setTheme(newTheme);
    };

    document
      .getElementById("theme-toggle")
      ?.addEventListener("click", toggleTheme);

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleMediaChange = (e) => {
      setTheme(e.matches ? "dark" : "light");
    };
    mediaQuery.addEventListener("change", handleMediaChange);

    return () => {
      document
        .getElementById("theme-toggle")
        ?.removeEventListener("click", toggleTheme);
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, [theme]);
  return (
    <div>
      {" "}
      <div class="main-wrapper">
        <nav className={`bg-${theme === "dark" ? "#000000" : theme} navbar`}>
          <div class="brand-and-icon">
            <a href="/home" class="navbar-brand">
              <img src="assets/img/logo-moc.png" alt="" />
            </a>
            <button type="button" class="navbar-toggler">
              <i class="bx bx-menu"></i>
            </button>
          </div>
          {/* <TelegramLoginExample /> */}

          <div class="navbar-collapse ">
            <ul class="navbar-nav">
              <li>
                <a class={`${theme === "dark" ? "text-light" : "text-dark"}`}>
                  Articles
                  <span class="drop-icon">
                    <i class="bx bx-caret-down"></i>
                  </span>
                </a>
                <div class="sub-menu">
                  <div class="sub-menu-item">
                    <h4>Top Resources</h4>
                    <ul>
                      <li>
                        <a>
                          Best Crypto Tax Software in 2024: Top 7 Tax Tools for
                          Crypto
                        </a>
                      </li>
                      <li>
                        <a>The Top 10 Best Crypto Telegram Channels</a>
                      </li>
                      <li>
                        <a>10 Best Crypto YouTube Channels to Watch in 2024</a>
                      </li>
                      <li>
                        <a href="#">
                          5 Best Hardware Wallets for 2024: Top Crypto Wallets
                          Reviewed!
                        </a>
                      </li>
                    </ul>
                    <a href="#" class="text-green">
                      VIEW ALL TOP RESOURCES{" "}
                      <i class="bx bx-arrow-from-left"></i>
                    </a>
                  </div>

                  <div class="sub-menu-item">
                    <h4>Beginner Education</h4>
                    <ul>
                      <li>
                        <a href="#">What is Bitcoin? A Simple Explanation</a>
                      </li>
                      <li>
                        <a href="#">
                          Ethereum 101: The Ultimate Guide to Understanding
                          Ethereum
                        </a>
                      </li>
                      <li>
                        <a href="#">What is Blockchain in Simple Terms?</a>
                      </li>
                      <li>
                        <a href="#">
                          What is a Smart Contract? Contracts on the Blockchain!
                        </a>
                      </li>
                    </ul>
                    <a href="#" class="text-green">
                      VIEW ALL BEGINNER EDUCATION{" "}
                      <i class="bx bx-arrow-from-left"></i>
                    </a>
                  </div>

                  <div class="sub-menu-item">
                    <h4> Hot Reviews</h4>
                    <ul>
                      <li>
                        <a href="#">
                          Binance Review 2024: Pros & Cons and In-Depth Exchange
                          Overview
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          OKX Review 2024: Powerful All-In-One Crypto Exchange!
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Trezor Review: Trezor One Vs Trezor Model T, Which is
                          Best for You?
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Koinly Review 2024: Crypto Tax Made Easy!
                        </a>
                      </li>
                    </ul>
                    <a href="#" class="text-green">
                      VIEW ALL HOT REVIEWS <i class="bx bx-arrow-from-left"></i>
                    </a>
                  </div>
                  <div class="sub-menu-item">
                    <img src="assets/img/news/news1.jpg" alt="MoC" />
                  </div>
                </div>
              </li>

              <li>
                <a class="menu-link">
                  Top Deals
                  <span class="drop-icon">
                    <i class="bx bx-caret-down"></i>
                  </span>
                </a>
                <div class="sub-menu">
                  <div class="sub-menu-item">
                    <h4>
                      <a href="#">Bybit</a>
                    </h4>
                    <ul>
                      <li>$60,000 Bonus + 0% Maker Fee For 30 Days</li>
                      <li>Crypto Copy Trading & Trading Competitions</li>
                    </ul>
                    <h4>
                      <a href="#">NGRAVE</a>
                    </h4>
                    <ul>
                      <li>Use code Mysteryofcrypto at checkout for 10% OFF!</li>
                      <li>The Coldest Hardware Wallet</li>
                    </ul>
                  </div>

                  <div class="sub-menu-item">
                    <h4>
                      <a href="#">OKX</a>
                    </h4>
                    <ul>
                      <li>Top Exotic Alt Exchange</li>
                      <li>40% Spot Trading Discount</li>
                    </ul>
                    <h4>
                      <a href="#">SwissBorg</a>
                    </h4>
                    <ul>
                      <li>Best Crypto Wealth Management Platform!</li>
                      <li>Deposit €50 GET UP TO €100 FREE!</li>
                    </ul>
                  </div>

                  <div class="sub-menu-item">
                    <h4>
                      <a href="#">Kucoin</a>
                    </h4>
                    <ul>
                      <li>Trading Fee Discount of Up To 60%</li>
                      <li>FREE Trading Bot!</li>
                    </ul>
                    <h4>
                      <a href="#">OKX</a>
                    </h4>
                    <ul>
                      <li>Top Exotic Alt Exchange</li>
                      <li>40% Spot Trading Discount</li>
                    </ul>
                  </div>
                  <div class="sub-menu-item">
                    <h4>
                      <a href="#">Trezor</a>
                    </h4>
                    <ul>
                      <li>Supports 1000+ Cryptos</li>
                      <li>Best Crypto Hardwallet</li>
                    </ul>
                    <h4>
                      <a href="#">FTM</a>
                    </h4>
                    <ul>
                      <li>Best Crypto Wealth Management Platform!</li>
                      <li>Deposit €50 GET UP TO €100 FREE!</li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <a href="#vide01">Videos</a>
              </li>
              <li>
                <a href="#air01">Airdrops</a>
              </li>
              {/* {address ? (
                <li>
                  <button
                    onClick={() => open()}
                    class="btn truncate btn-outline-info font-weight-bold"
                  >
                    {address?.slice(0, 10)}...
                  </button>
                </li>
              ) : (
                <li>
                  <button
                    onClick={() => open()}
                    class="btn btn-outline-info font-weight-bold"
                  >
                    Connect
                  </button>
                </li>
              )} */}

              <li class="swtchr">
                <button
                  id="theme-toggle"
                  class="theme-toggle"
                  title="Toggles light & dark"
                  aria-label="auto"
                  aria-live="polite"
                >
                  <span class="icon theme-toggle__sun">
                    <img
                      class="icon__svg"
                      src="assets/img/moon.png"
                      style={{ width: "25px", height: "25px" }}
                      alt=""
                    />
                  </span>
                  <span class="icon theme-toggle__moon">
                    <img
                      class="icon__svg"
                      src="assets/img/sun.png"
                      style={{ width: "25px", height: "25px" }}
                      alt=""
                    />
                  </span>
                </button>
              </li>
              <li>
                <form class="search">
                  <input
                    type="text"
                    class="search__input"
                    placeholder="Search"
                  />
                  <i class="bx bx-search search__icon"></i>
                </form>
              </li>
              {/* <li>
                <a>
                  {" "}
                  <SideDrawer />
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}
