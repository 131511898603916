import React, { useContext, useEffect, useState } from "react";
import "../Influencer.css";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import HeaderHome from "../../HeaderHome";
import Slider from "react-slick";

export default function Influencer() {
  const [data, setData] = useState();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [article, setArticle] = useState();

  const urlParams = new URLSearchParams(window.location.search);
  const refral = urlParams.get("Referral");

  useEffect(() => {
    localStorage.setItem("refral", refral ? refral : 0);
  }, [refral]);

  const { setId } = useContext(StakingApp);
  const { theme } = useContext(StakingApp);
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const AddProject = () => {
    axiosInstance
      .get(API_URLS.TopdealClientList)
      .then((res) => {
        setData(res.data.clientList);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    AddProject();
  }, []);

  const getArticle = () => {
    axiosInstance
      .get(API_URLS.Articlelist)
      .then((res) => {
        setArticle(res.data.articleList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getArticle();
  }, []);
  useEffect(() => {
    AddProject();
  }, []);
  return (
    <div>
      <HeaderHome />
      <section class={`influbg bg-${theme === "dark" ? "#000000" : theme} `}>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-8 order-md-1 order-2">
              <h5 className="text-md-left text-center">
                "Mysteryofcrypto" is one stop solution for all your crypto
                queries. We're serving in crypto space for more than 5 years and
                helped many people to lead in this industry.
              </h5>
              <h5 className="text-md-left text-center">
                Our purpose is to contribute to the growth of Crypto/Blockchain
                and bring more awareness towards this revolutionary Tech.
              </h5>
              <h5 className="text-md-left text-center">
                Here on MOC you'll learn about cryptocurrency, NFTs, Defi,
                metaverse, finance, market cycles etc. As we have good
                connections with industry experts, we always try to bring some
                market insights for our community.
              </h5>
              <h3 className="text-theme-color mt-4 text-md-left text-center">
                Lucky
              </h3>
              <h5 className="text-md-left text-center">Director - MoC</h5>
              <div className="mt-3">
                <div className="social-links text-md-left text-center mt-3">
                  <a
                    href="https://twitter.com/Mysteryofcrypt"
                    target="_blank"
                    class="twitter"
                  >
                    <i className="bx bx_x">
                      <img src="assets/img/x.png" alt="" />
                    </i>
                  </a>
                  <a
                    href="https://t.me/Mysteryofcrypto"
                    target="_blank"
                    class="linkedin"
                  >
                    <i class="bx bxl-telegram"></i>
                  </a>
                  <a
                    href="Www.youtube.com/c/mysteryofcrypto"
                    target="_blank"
                    class="Youtube"
                  >
                    <i class="bx bxl-youtube"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/mysteryofcrypto/"
                    target="_blank"
                    class="instagram"
                  >
                    <i class="bx bxl-instagram"></i>
                  </a>
                </div>
                {/* <a href="/home">
                  <button class="glow-btn">Explore MoC</button>
                </a> */}
              </div>
            </div>
            <div class="col-md-3 influ offset-md-1 text-center order-md-2 order-1">
              <img
                src="assets/img/lucky.png"
                class="img-fluid rcb"
                alt="Lucky"
              />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section class="st02 about">
          <div class="container">
            <div class="section-title d-flex justify-content-between align-items-center">
              <div>
                <h2>Top Articles</h2>
              </div>
              <div>
                <a>
                  View All <i class="bx bx-arrow-from-left"></i>
                </a>
              </div>
            </div>
            <div class="blog_section">
              <div class="blog_content">
                <div class="owl-carousel owl-theme owl-loaded owl-drag">
                  <div class="owl-stage-outer">
                    <div
                    // class="owl-stage"
                    // style={{
                    //   transform: "translate3d(-1298px, 0px, 0px)",
                    //   transition: "all 0s ease 0s; width: 3894px",
                    // }}
                    >
                      <Slider {...settings}>
                        {article?.map((i) => {
                          return (
                            <div
                              class="owl-item cloned"
                              style={{ width: "300.5px", marginRight: "10px" }}
                            >
                              <div class="blog_item">
                                <div
                                  onClick={() => {
                                    navigate(`/articles`);
                                    localStorage.setItem("articlesId", i.id);
                                  }}
                                  class="blog_image"
                                >
                                  <div class="ng5">
                                    <img
                                      src={i.imagePath}
                                      class="img-fluid rounded-full"
                                      alt="MOC"
                                    />
                                  </div>
                                </div>
                                <div class="blog_details">
                                  <h2 className="ovrfl01">{i.title}</h2>
                                  <div class="blog_title">
                                    <p class="ovrfl02 card-text article-content">
                                      {i.subtitle}
                                    </p>
                                    <div class="d-flex justify-content-between align-items-center">
                                      <small>{i.created_at}</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                  {/* <div class="owl-nav disabled">
                    <button type="button" role="presentation" class="owl-prev">
                      <span aria-label="Previous">‹</span>
                    </button>
                    <button type="button" role="presentation" class="owl-next">
                      <span aria-label="Next">›</span>
                    </button>
                  </div> */}
                  <div class="owl-dots disabled"></div>
                </div>
              </div>
            </div>
            <div id="deal01"></div>
          </div>
        </section>

        {/* <section
          class={`about position-relative bg-${
            theme === "dark" ? "#000000" : theme
          }`}
        >
          <div class="ellipse-glow-item ellipse-1"></div>
          <div class="container">
            <div class="section-title d-flex justify-content-between align-items-center">
              <div>
                <h2>Top Deals</h2>
              </div>
              <div>
                <Link to="./home">
                  {" "}
                  View All <i class="bx bx-arrow-from-left"></i>
                </Link>
              </div>
            </div>

            <div class="row">
              {data?.map((it, i) => {
                return (
                  <div class="col-lg-3 col-md-6">
                    <div
                      onClick={() => {
                        navigate(`/home`);
                        localStorage.setItem("id", it.id);
                      }}
                      className="tdradius td001 td_bg1"
                    >
                      <div className="flex gap-3">
                        <div className="tdeals">
                          <img src={it.bannerLogoPath} alt="MoC" />
                        </div>
                        <div className="flex flex-col justify-center ">
                          <p className="ovrfl01 font-bold text-white">
                            {it.nametitle}
                          </p>
                          <p className="ovrfl02 text-white  text-sm tru">
                            {it.clientDescribtion?.slice(0, 50)}...
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between items-center w-full bg-opacity-60 rounded-md p-2">
                        <div className="flex flex-col justify-center items-center">
                          <p className="text-sm pl01 text-white">
                            Quests <span> {it.totalproject}</span>
                          </p>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                          <div className="flex justify-center items-center gap-3 mt-1">
                            <img
                              src={it.tokenLogoPath}
                              alt=""
                              className="h-5 rounded-full"
                            />
                            <p className="text-sm text-gray-300">
                              {it.tokenname}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section> */}
        <section class="about position-relative">
          <div class="ellipse-glow-item ellipse-1"></div>
          <div class="container">
            <div class="section-title d-flex justify-content-between align-items-center">
              <div>
                <h2>Top Deals</h2>
              </div>
              <div>
                <a href="#">
                  View All <i class="bx bx-arrow-from-left"></i>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="tdradius td001 td_bg1">
                  <div class="coinbox">
                    <div class="cncover">
                      <img src="assets/img/deals/baybit.svg" alt="MoC" />
                    </div>
                    <div class="cname">Bybit</div>
                  </div>
                  <div class="tdtext">
                    <p>$60,000 Bonus + 0% Maker Fee For 30 Days</p>
                    <p>Crypto Copy Trading &amp; Trading Competitions</p>
                  </div>
                  <div class="tdbtn_cover">
                    <div class="btn1">
                      <a href="/userpro">
                        <button>Show More</button>
                      </a>
                    </div>
                    <div class="btn2">
                      <a href="#">
                        <button>Sign Up</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="tdradius td001 td_bg1">
                  <div class="coinbox">
                    <div class="cncover">
                      <img src="assets/img/deals/kucoin.svg" alt="MoC" />
                    </div>
                    <div class="cname">Kucoin</div>
                  </div>
                  <div class="tdtext">
                    <p>$60,000 Bonus + 0% Maker Fee For 30 Days</p>
                    <p>Crypto Copy Trading &amp; Trading Competitions</p>
                  </div>
                  <div class="tdbtn_cover">
                    <div class="btn1">
                      <a href="/coindetail">
                        <button>Show More</button>
                      </a>
                    </div>
                    <div class="btn2">
                      <a href="#">
                        <button>Sign Up</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="tdradius td001 td_bg1">
                  <div class="coinbox">
                    <div class="cncover">
                      <img src="assets/img/deals/okx.svg" alt="MoC" />
                    </div>
                    <div class="cname">OKX</div>
                  </div>
                  <div class="tdtext">
                    <p>$60,000 Bonus + 0% Maker Fee For 30 Days</p>
                    <p>Crypto Copy Trading &amp; Trading Competitions</p>
                  </div>
                  <div class="tdbtn_cover">
                    <div class="btn1">
                      <a href="/coindetail">
                        <button>Show More</button>
                      </a>
                    </div>
                    <div class="btn2">
                      <a href="#">
                        <button>Sign Up</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="tdradius td001 td_bg1">
                  <div class="coinbox">
                    <div class="cncover">
                      <img src="assets/img/deals/maker.svg" alt="MoC" />
                    </div>
                    <div class="cname">Maker</div>
                  </div>
                  <div class="tdtext">
                    <p>$60,000 Bonus + 0% Maker Fee For 30 Days</p>
                    <p>Crypto Copy Trading &amp; Trading Competitions</p>
                  </div>
                  <div class="tdbtn_cover">
                    <div class="btn1">
                      <a href="#">
                        <button>Show More</button>
                      </a>
                    </div>
                    <div class="btn2">
                      <a href="#">
                        <button>Sign Up</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="tdradius td001 td_bg1">
                  <div class="coinbox">
                    <div class="cncover">
                      <img src="assets/img/deals/aave.svg" alt="MoC" />
                    </div>
                    <div class="cname">Aave</div>
                  </div>
                  <div class="tdtext">
                    <p>$60,000 Bonus + 0% Maker Fee For 30 Days</p>
                    <p>Crypto Copy Trading &amp; Trading Competitions</p>
                  </div>
                  <div class="tdbtn_cover">
                    <div class="btn1">
                      <a href="#">
                        <button>Show More</button>
                      </a>
                    </div>
                    <div class="btn2">
                      <a href="#">
                        <button>Sign Up</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="tdradius td001 td_bg1">
                  <div class="coinbox">
                    <div class="cncover">
                      <img src="assets/img/deals/synthetix.svg" alt="MoC" />
                    </div>
                    <div class="cname">Synthetix</div>
                  </div>
                  <div class="tdtext">
                    <p>$60,000 Bonus + 0% Maker Fee For 30 Days</p>
                    <p>Crypto Copy Trading &amp; Trading Competitions</p>
                  </div>
                  <div class="tdbtn_cover">
                    <div class="btn1">
                      <a href="#">
                        <button>Show More</button>
                      </a>
                    </div>
                    <div class="btn2">
                      <a href="#">
                        <button>Sign Up</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class={`st03 about bg-${theme === "dark" ? "#000000" : theme}`}
        >
          <div class="container">
            <div class="section-title d-flex justify-content-between align-items-center">
              <div>
                <h4 class="text-theme-color">Know here</h4>
                <h2>How MoC Works</h2>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-2">
                <div class="blog_item">
                  <div class="mocworks tm009 blog_details">
                    <div class="blog_title">
                      <h2>Engage in Tasks</h2>
                      <p class="card-text article-content text-white">
                        Participate in <br />
                        tasks
                      </p>
                      <div class="d-flex justify-content-between align-items-center">
                        <small class="text-muted">
                          <a>Dive into Tasks</a>
                        </small>
                      </div>
                    </div>
                    <span class="blogicon">
                      <i class="bx bx-task-x"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-2">
                <div class="blog_item">
                  <div class="mocworks tm009 blog_details">
                    <div class="blog_title">
                      <h2>Earn Rewards</h2>
                      <p class="card-text article-content">
                        Obtain Crypto Tokens, NFTs, and Gain Exclusive Access
                      </p>
                      <div class="d-flex justify-content-between align-items-center">
                        <small class="text-muted">
                          <a>Begin Earning</a>
                        </small>
                      </div>
                    </div>
                    <span class="blogicon">
                      <i class="bx bxs-trophy"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-2">
                <div class="blog_item">
                  <div class="mocworks tm009 blog_details">
                    <div class="blog_title">
                      <h2>Upgrade Your Profile</h2>
                      <p class="card-text article-content">
                        Accrue Experience, Unlock Fresh Benefits
                      </p>
                      <div class="d-flex justify-content-between align-items-center">
                        <small class="text-muted">
                          <a>Discover Your Profile</a>
                        </small>
                      </div>
                    </div>
                    <span class="blogicon">
                      <i class="bx bx-user-check"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container mt-5">
            <div class="row">
              <div class="col-lg-12">
                <div class="joinbox blog_item">
                  <div class="mb-0 tm009 blog_details">
                    <div class="blog_title">
                      <span class="mocicon">
                        <img src="assets/img/moc_icon.png" alt="Moc" />
                      </span>
                      <h1 class="text-white">Join forces with</h1>
                      <h2>
                        100+ projects & engage <br />
                        50k users
                      </h2>
                      <div class="btn3">
                        <Link to="https://admin.aquaeal.in" target="_blank">
                          <button>Launch Tasks </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="vide01"></div>
        </section>

        {/* <!--start coverage Section --> */}

        <section
          class={`st04 about bg-${theme === "dark" ? "#000000" : theme}`}
        >
          <div class="container">
            <div class="section-title d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <h4 class="text-theme-color">Exploring Through</h4>
                <h2>Video Articles</h2>
              </div>
              <div>
                <a>
                  View All <i class="bx bx-arrow-from-left"></i>
                </a>
              </div>
            </div>
            <div class="row content">
              <div class="col-lg-12 col-md-6">
                <div class="blog_content">
                  <div class="row">
                    <div class="col-lg-4 col-md-2">
                      <div class="blog_item">
                        <div>
                          <iframe
                            width="100%"
                            height="250"
                            src="https://www.youtube.com/embed/GyTko3zUuXY?si=TzH0rdmx0kQFTYDf"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div class="blog_details">
                          <div class="blog_title">
                            <div class="button">
                              <a class="text-white">
                                <h4 class="card-title article-heading">
                                  Crypto News: BTC, Uniswap vs. The SEC, ETH,
                                  TON, USDT & MORE!!
                                </h4>
                              </a>
                            </div>

                            <div class="d-flex justify-content-between align-items-center">
                              <small>Posted on: April 17, 2024</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-2">
                      {" "}
                      <div class="blog_item">
                        <div>
                          <iframe
                            width="100%"
                            height="250"
                            src="https://www.youtube.com/embed/GyTko3zUuXY?si=TzH0rdmx0kQFTYDf"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div class="blog_details">
                          <div class="blog_title">
                            <div class="button">
                              <a class="text-white">
                                <h4 class="card-title article-heading">
                                  Crypto News: BTC, Uniswap vs. The SEC, ETH,
                                  TON, USDT & MORE!!
                                </h4>
                              </a>
                            </div>

                            <div class="d-flex justify-content-between align-items-center">
                              <small>Posted on: April 17, 2024</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-2">
                      {" "}
                      <div class="blog_item">
                        <div>
                          <iframe
                            width="100%"
                            height="250"
                            src="https://www.youtube.com/embed/GyTko3zUuXY?si=TzH0rdmx0kQFTYDf"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div class="blog_details">
                          <div class="blog_title">
                            <div class="button">
                              <a class="text-white">
                                <h4 class="card-title article-heading">
                                  Crypto News: BTC, Uniswap vs. The SEC, ETH,
                                  TON, USDT & MORE!!
                                </h4>
                              </a>
                            </div>

                            <div class="d-flex justify-content-between align-items-center">
                              <small>Posted on: April 17, 2024</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class={`about position-relative bg-${
            theme === "dark" ? "#000000" : theme
          }`}
        >
          <div class="ellipse-glow-item ellipse-1"></div>
          <div class="container">
            <div class="section-title d-flex justify-content-between align-items-center">
              <div class="text-left">
                <h4 class="text-theme-color">We are</h4>
                <h2>Trusted by</h2>
              </div>
            </div>
            <div class="row fpartners">
              <div class="col-lg-2 col-md-4 col-6">
                <div class="stcover">
                  <img
                    src="assets/img/clients/client1.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-lg-2 col-md-4 col-6">
                <div class="stcover">
                  <img
                    src="assets/img/clients/client2.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-lg-2 col-md-4 col-6">
                <div class="stcover">
                  <img
                    src="assets/img/clients/client3.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-lg-2 col-md-4 col-6">
                <div class="stcover">
                  <img
                    src="assets/img/clients/client4.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-lg-2 col-md-4 col-6">
                <div class="stcover">
                  <img
                    src="assets/img/clients/client5.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-lg-2 col-md-4 col-6">
                <div class="stcover">
                  <img
                    src="assets/img/clients/client6.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="air01"></div>
        </section>
      </main>
      <footer
        id="footer"
        class={`position-relative bg-${theme === "dark" ? "#000000" : theme}`}
      >
        <section class="about">
          <div class="container">
            <div class="row content align-items-center">
              <div class="col-lg-7 col-md-6">
                <div class="section-title">
                  <div>
                    <h4 class="text-theme-color">Get Crypto Airdrop</h4>
                    <h2 class="text-title">
                      Get Ready for Exciting Rewards - Introducing Our Crypto
                      Airdrop!
                    </h2>
                    <h4>
                      Participate in Our Exclusive Crypto Airdrop Program Today!
                    </h4>
                    <p>
                      As part of our commitment to fostering community
                      engagement and rewarding our loyal users, we are launching
                      an exciting initiative to distribute free tokens to
                      participants.
                    </p>
                    <div>
                      <div class="btn1">
                        <a>
                          <button>Participate Now</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <img
                  src="assets/img/airdrop.png"
                  class="img-fluid mt-4"
                  alt="MoC"
                />
              </div>
            </div>
          </div>
        </section>
        <div class="st06 footer-newsletter">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="bgsubscribe">
                  <div>
                    <h4>Free Crypto Coverage</h4>
                    <h2 class="text-center text-uppercase">
                      Direct to Your Inbox
                    </h2>
                  </div>
                  <h5>
                    Subscribe now for exclusive updates, offers, events, and
                    insights.
                  </h5>
                  <div class="row justify-content-center">
                    <div class="col-md-6">
                      <form class="mocform" action="" method="post">
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter your email id"
                        />
                        <input type="submit" value="Subscribe" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-top position-relative">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6 footer-contact">
                <div class="footer_logo">
                  <img src="assets/img/moc_icon.png" alt="" />
                </div>
                <p>
                  We provide advisory services to the companies working in the
                  Crypto, Web3 and Metaverse space, with the help of our vast
                  experience and expertise in the field.
                </p>
              </div>

              <div class="col-lg-2 offset-lg-1 col-md-6 col-6 footer-links">
                <h4>Company</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Home</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>About us</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Social</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Terms of service</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-2 offset-lg-1 col-md-6 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <a class="border border-info px-3 py-2">Airdrop</a>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Top Deals</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Articles</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>News</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Videos</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>

                <div class="social-links mt-3">
                  <a
                    href="https://twitter.com/Mysteryofcrypt"
                    target="_blank"
                    class="twitter"
                  >
                    <i class="bx bx_x">
                      <img src="assets/img/x.png" alt="" />
                    </i>
                  </a>
                  <a
                    href="https://t.me/Mysteryofcrypto"
                    target="_blank"
                    class="linkedin"
                  >
                    <i class="bx bxl-telegram"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/c/mysteryofcrypto"
                    target="_blank"
                    class="Youtube"
                  >
                    <i class="bx bxl-youtube"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/mysteryofcrypto/"
                    target="_blank"
                    class="instagram"
                  >
                    <i class="bx bxl-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container footer-bottom clearfix">
          <div class="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>
                Mystery of Crypto - <span class="text-theme-color">MOC</span>
              </span>
            </strong>
          </div>
          <div class="credits"></div>
        </div>
      </footer>
    </div>
  );
}
