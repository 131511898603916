import React, { useContext, useEffect, useState } from "react";
import SideDrawer from "../Pages/SideDrawer";
import { StakingApp } from "../UserDasbord/StateMengmanet";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { Link, useNavigate } from "react-router-dom";
import Sign from "../Sign";

export default function Header() {
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const navagte = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { theme, setTheme } = useContext(StakingApp);

  function setPreference() {
    localStorage.setItem("theme-preference", theme);
    reflectPreference();
  }

  function reflectPreference() {
    document.documentElement.setAttribute("data-theme", theme);
    document.getElementById("theme-toggle")?.setAttribute("aria-label", theme);
  }

  useEffect(() => {
    reflectPreference();
    setPreference();

    const toggleTheme = () => {
      const newTheme = theme === "dark" ? "dark" : "dark";
      setTheme(newTheme);
    };

    document
      .getElementById("theme-toggle")
      ?.addEventListener("click", toggleTheme);

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleMediaChange = (e) => {
      setTheme(e.matches ? "dark" : "dark");
    };
    mediaQuery.addEventListener("change", handleMediaChange);

    return () => {
      document
        .getElementById("theme-toggle")
        ?.removeEventListener("click", toggleTheme);
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, [theme]);
  return (
    <div>
      {" "}
      <div class="main-wrapper">
        <nav className={`bg-${theme === "dark" ? "#000000" : theme} navbar`}>
          <div class="brand-and-icon">
            <a
              onClick={() => {
                navagte("/home");
                localStorage.setItem("id", 0);
              }}
              class="navbar-brand"
            >
              <img src="assets/img/logo-moc.png" alt="" />
            </a>
            <button type="button" class="navbar-toggler">
              <i class="bx bx-menu"></i>
            </button>
          </div>
          <Sign />

          <div class="navbar-collapse ">
            <ul class="navbar-nav">
              <li>
                <Link to="/all-task" class="menu-link">
                  All Task
                </Link>
              </li>
              <li>
                <Link to="/leader-board" class="menu-link">
                  Reward
                </Link>
              </li>
              <li onClick={handleClick1}>
                <Link to="#vide01">
                  Explore Space
                  <span class="drop-icon">
                    <i class="bx bx-caret-down"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/CryptoSpace" class="menu-link">
                  MoC Crypto Space
                </Link>
              </li>

              <li onClick={handleClick} class="drop-down">
                <Link to="#air01" class="menu-link">
                  Community
                  <span class="drop-icon">
                    <i class="bx bx-caret-down"></i>
                  </span>
                </Link>
              </li>

              <li class="swtchr">
                <button
                  id="theme-toggle"
                  class="theme-toggle"
                  title="Toggles light & dark"
                  aria-label="auto"
                  aria-live="polite"
                >
                  <span class="icon theme-toggle__sun">
                    <img
                      class="icon__svg"
                      src="assets/img/moon.png"
                      style={{ width: "25px", height: "25px" }}
                      alt=""
                    />
                  </span>
                  <span class="icon theme-toggle__moon">
                    <img
                      class="icon__svg"
                      src="assets/img/sun.png"
                      style={{ width: "25px", height: "25px" }}
                      alt=""
                    />
                  </span>
                </button>
              </li>
              <li>
                <form class="search">
                  <input
                    type="text"
                    class="search__input"
                    placeholder="Search"
                  />
                  <i class="bx bx-search search__icon"></i>
                </form>
              </li>
              <li>
                <a>
                  {" "}
                  <SideDrawer />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        PaperProps={{
          className: "!bg-[#1BCED1]",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem className="text-white" onClick={handleClose}>
          Blog
        </MenuItem>
        <MenuItem className="text-white" onClick={handleClose}>
          Twitter
        </MenuItem>
        <MenuItem className="text-white" onClick={handleClose}>
          Telegram
        </MenuItem>
      </Menu>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl1}
        open={open1}
        onClose={handleClose1}
        TransitionComponent={Fade}
        PaperProps={{
          className: "!bg-[#1BCED1]",
        }}
      >
        <MenuItem
          className="text-white"
          onClick={() => {
            handleClose1();
            navagte("/clinte");
            localStorage.setItem("id", 0);
          }}
        >
          Your Client
        </MenuItem>
        <MenuItem className="text-white" onClick={handleClose}>
          Current
        </MenuItem>
        <MenuItem className="text-white" onClick={handleClose}>
          Upcoming
        </MenuItem>
      </Menu>
    </div>
  );
}
