import React, { useContext, useDebugValue, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import image from "../../Img/tX.png";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Box, CircularProgress } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";

export default function Login() {
  const { theme, setOpen } = useContext(StakingApp);
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { open } = useWeb3Modal();
  const refral = localStorage.getItem("refral");
  const path = localStorage.getItem("path");

  const UserSignup = () => {
    axios({
      url: `https://restapi.aquaeal.in/UserApi/Googlelogin/${refral}`,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": "MOC!#321",
      },
      data: { jwtToken: user?.credential },
    })
      .then((res) => {
        toast.success(res.data.message);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userID", res.data.userdata.id);
        localStorage.setItem(
          "twitterLinkstatus",
          res.data.userdata.twitterLinkstatus
        );
        goToHem();
        // if (path?.length > 0) {
        //   navigate(`/${path}`);
        //   console.log("hello");
        // } else {
        //   navigate(`/home`);
        //   console.log("hey");
        // }
      })
      .catch((err) => {
        console.log(err);

        toast.warn(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    user?.credential && UserSignup();
  }, [user?.credential]);

  const goToHem = () => {
    if (path) {
      navigate(`/${JSON.parse(path)}`);
    } else {
      navigate("/home");
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/home");
    }
  }, [token]);

  return (
    <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
      <main className="regbox" id="main">
        <section className="headermain">
          <div className="container">
            <div className="row align-items-center justify-content-center ">
              <div className="col-lg-6 offset-lg-1">
                <div className="bgsubscribe">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <h3 className="my-2 fs-5 font-bold ">Connect a Wallet</h3>
                      <a>
                        <div
                          onClick={() => open()}
                          className=" metamsk flex justify-center items-center bg-white py-3 gap-5 rounded cursor-pointer"
                        >
                          <span>
                            <img
                              src="assets/img/metamask.png"
                              alt=""
                              className="h-8"
                            />
                          </span>{" "}
                          <p className="text-black font-bold text-sm">
                            Metamask
                          </p>
                        </div>
                      </a>
                      <div className="or09">
                        <span className="org">OR</span>
                      </div>
                      <div
                        // onClick={UserSignup}
                        className="swg flex justify-center items-center bg-white py-3 gap-5 rounded cursor-pointer"
                      >
                        <GoogleLogin
                          onSuccess={(credentialResponse) => {
                            setUser(credentialResponse);
                          }}
                          onError={() => {
                            console.log("Login Failed");
                          }}
                          className="text-black font-bold text-sm border-none "
                        />
                        {/* <p>Signup With Google</p> */}
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <h3 className="font-bold text-2xl my-1">
                        What is Wallet?{" "}
                      </h3>
                      <div className={`bg-${theme} p-3 mt-3 rounded-2xl`}>
                        <p className="font-weight-bold">
                          A Home for your Digital Assets
                        </p>
                        <p className="small">
                          Wallets are used to send, receive, store, and display
                          digital assets like Ethereum and NFTs.
                        </p>
                        <br></br>
                        <p className="font-weight-bold">A New Way to Log In</p>
                        <p className="small">
                          Instead of creating new accounts and passwords on
                          every website, just connect your wallet.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
