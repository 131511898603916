import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import Loder from "../Loder";
import Home from "../Home";
import { toast } from "react-toastify";

const Clinte = () => {
  const [isLoding, setIsLodin] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const { theme } = useContext(StakingApp);
  const [filter, setFilter] = useState("all");
  const token = localStorage.getItem("token");
  const userID = localStorage.getItem("userID");

  const AddProject = () => {
    setIsLodin(true);
    axiosInstance
      .get(API_URLS.ClientList + `/${userID}/${filter}`)
      .then((res) => {
        setData(res.data.clientList);
        setIsLodin(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLodin(false);
      });
  };

  const Fallow = (id) => {
    axiosInstance
      .post(API_URLS.clientfollowingbyuser + `/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        AddProject();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    AddProject();
  }, [filter]);
  return (
    <>
      {isLoding ? (
        <Loder />
      ) : (
        <section
          className={`bg-${
            theme === "dark" ? "#000000" : theme
          } prflbg about innnerpage`}
        >
          <Home />
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="flex items-center justify-between my-3 px-5">
                  <div className="flex items-center gap-20 w-[70%]">
                    <p
                      onClick={() => setFilter("all")}
                      className={`${
                        filter === "all"
                          ? "border-b cursor-pointer"
                          : "cursor-pointer"
                      }`}
                    >
                      All
                    </p>
                    <p
                      className={`${
                        filter === "following"
                          ? "border-b cursor-pointer"
                          : "cursor-pointer"
                      }`}
                      onClick={() => setFilter("following")}
                    >
                      Following
                    </p>
                  </div>
                </div>
                <div class="tab">
                  <div>
                    <div className="row mb-4">
                      <div className="col-lg-8 col-md-8">
                        <div className="fl09">
                          <div className="search02">
                            <span className="bx bx-search-alt-2"></span>
                            <input placeholder="Search" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="fl09 text-center cursor-pointer fnt01">
                          <i className="bx bx-filter"></i> Show Trending
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="fl09 text-center cursor-pointer fnt01">
                          <i className="bx  bx-check-circle"></i> Available to
                          claim
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 mb-4"></div>
                  <div class="row">
                    {data?.map((it, i) => {
                      return (
                        <div class="col-lg-3 col-md-6">
                          <div className="flex flex-col gap-2 proswitch bg-opacity-75 p-3 mb-3 rounded cursor-pointer">
                            <span className="flex justify-end">
                              {it.follow ? (
                                <div
                                  onClick={() => Fallow(it.id)}
                                  className="pl01 d-inline-block text-end bg-green-400 border-0"
                                >
                                  Following
                                </div>
                              ) : (
                                <>
                                  {token ? (
                                    <div
                                      onClick={() => Fallow(it.id)}
                                      className="pl01 d-inline-block text-end"
                                    >
                                      Follow +
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => navigate("/login")}
                                      className="pl01 d-inline-block text-end"
                                    >
                                      Follow +
                                    </div>
                                  )}
                                </>
                              )}
                            </span>
                            <div
                              onClick={() => {
                                navigate(`/home`);
                                localStorage.setItem("id", it.id);
                              }}
                              className="row"
                            >
                              <div className="col-md-3">
                                <div className="tdeals2">
                                  {" "}
                                  <img
                                    src={it.bannerLogoPath}                                 
                                    alt="MoC"
                                  />
                                </div>
                              </div>
                              <div className="col-md-9 flex-grow-1 flex-col">
                                <h4 className="font-bold  ovrfl01">
                                  {it.nametitle}
                                </h4>
                                <p className="text-sm ovrfl02">
                                  {it.clientDescribtion}...
                                </p>
                              </div>
                            </div>
                            <div className="row mt-2">
                              {" "}
                              <div className="col-12">
                                <div className="flex justify-between items-center mt-2">
                                  <div className="flex flex-col justify-center items-center">
                                    <p className="text-sm text-white pl01">Quests <span> {it.totalproject}</span>
                                    </p>
                                   
                                  </div>
                                  <div className="flex flex-col justify-center items-center">
                                                                       <div className="flex justify-center items-center gap-3 mt-1">
                                      <img
                                        src={it.tokenLogoPath}
                                        alt=""
                                        className="h-5"
                                      />
                                      <p className="text-sm text-gray-300">
                                        {it.tokenname}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Clinte;
