import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance1 from "../../Config/axiosuser";
import check from "../../Img/check1.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import Loder from "../../Pages/Loder";
import Nodata from "../../Pages/Nodata";
import axios from "axios";
import { useAccount } from "wagmi";

export default function Webtask() {
  //   const { setOpen, setYOpen } = useContext(StakingApp);
  const { address } = useAccount();
  const [open1, setOpen1] = useState(false);
  const { theme } = useContext(StakingApp);
  const [statusX, setStatusX] = useState({});
  const token = localStorage.getItem("token");

  const [data, setData] = useState();
  const id = localStorage.getItem("id2");
  const [see, setSee] = useState();
  const [isLoding, setIsLoding] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [isLodin, setIsLodin] = useState(false);
  const navigator = useNavigate();
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [view, setView] = useState("grid");
  const projrct = localStorage.getItem("projrct");
  const category = localStorage.getItem("category");
  const clientId = localStorage.getItem("clientId");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

    setSelectedCheckbox("");
  };
  const openWindow = (id) => {
    const width = 1000;
    const height = 800;
    const leftPosition = (window.screen.width - width) / 2;
    const topPosition = (window.screen.height - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${leftPosition},top=${topPosition},resizable=yes,scrollbars=yes,status=yes`;

    const newWindow = window.open(`${id}`, "_blank", windowFeatures);
    if (newWindow) {
      newWindow.focus();
    } else {
      alert("Popup blocked! Please allow popups for this site.");
    }
  };

  const handleClose = () => {
    setOpen1(false);
    setSee("");
  };
  const handelopen = (id) => {
    setOpen1(true);
    setSee(data?.taskdata?.filter((data) => data.id === id));
  };
  console.log(data?.taskdata?.length === 0, "data?.taskdata?.length()===0");
  const AddProject = () => {
    setIsLodin(true);
    axiosInstance
      .get(
        API_URLS.gettasklistbyclientprojectcate +
          `/${clientId}/${projrct}/${category}`
      )
      .then((res) => {
        setData(res.data);
        setIsLodin(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLodin(false);
      });
  };

  const POstTweet = (id) => {
    setIsLoding(true);
    axiosInstance1
      .post(API_URLS.UserWeb3Task, { taskid: id })
      .then((res) => {
        toast.success(res.data.message);
        setIsLoding(false);

        // if (res.data.URL) {
        //   window.open(`${res.data.URL}`, "_blank");
        // }

        setStatusX({
          status: res.data.TaskStatus,
          point: res.data.TaskPoint,
          message: res.data.message,
        });
        AddProject();
      })
      .catch((err) => {
        console.log(err);
        setIsLoding(false);
      });
  };

  const accessToken =
    "EAAaBRlVmHykBO2zFQQ8IZAz7bhbpoFBrfNt5gGOcLxO7gqB3HcUf2BGHuEdXL0NHnkvlwAfmTwylTJ1DdNPR9Mh2cdusfC4exgif2oZAPPC5sOA1qMlxpDsrqokpWqs7YD4bu29LcjIhasO6yRNIIKk3G5N063xIZBxi7eqwjeP8NZA5T0niZBBTqTMBfRUfX88qieBzJsWcVld6rqED6mRMy2yazC4GcS8VV3IdbjY91wZClMcOBa";
  const postId = 670443951778088;

  function likeFacebookPost() {
    const url = `https://graph.facebook.com/v13.0/${postId}/likes`;

    axios
      .post(url, null, {
        params: {
          access_token: accessToken,
        },
      })
      .then((response) => {
        console.log("Post liked successfully.");
        console.log(response);
      })
      .catch((error) => {
        if (error.response) {
          console.log(`Error: ${error.response.data}`);
        } else {
          console.log(`Error: ${error.message}`);
        }
      });
  }

  useEffect(() => {
    likeFacebookPost();
  }, []);

  useEffect(() => {
    AddProject();
  }, []);

  useEffect(() => {
    AddProject();
  }, []);

  return (
    <>
      {isLodin ? (
        <Loder />
      ) : (
        <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
          <main id="main">
            <section class="prflbg about innnerpage">
              <div class="container">
                <div class="section-title">
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <div className="flex flex-wrap w-full justify-between items-center mt-5 md:gap-5 gap-2">
                        <div class="cncover2">
                          <img
                            className="rounded-xl"
                            src={data?.projectdata?.projectBannerPath}
                            alt="baybit"
                          />
                        </div>
                        <span className="flex flex-col ">
                          <h2>{data?.projectdata?.projectTitle}</h2>
                          <p class=" ">
                            {data?.projectdata?.projectDescription}
                          </p>
                        </span>
                      </div>

                      <div class="flex flex-wrap justify-between items-center">
                        <p className="hidden md:block"></p>
                        <div className="mvmnt ">
                          <div
                            onClick={() => navigator("/TaskDtl")}
                            className="row align-items-center cursor-pointer"
                          >
                            <div className="col-lg-3">
                              <div className="hstimg01">
                                <img
                                  src={data?.projectdata?.clientLogo}
                                  class="img-fluid"
                                  alt=""
                                  style={{ maxHeight: "90px" }}
                                />
                                <span className="chkover">
                                  <img
                                    src={data?.projectdata?.clientTokenLogo}
                                    class="img-fluid"
                                    alt=""
                                    style={{ maxHeight: "25px" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <h3 className="text-white small">
                                {data?.projectdata?.clientTitle}
                              </h3>
                              <span className="small2 text-white-50">
                                197K+ Followers
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="my-2" />
                      <h2 class="mt-md-4 my-4">How to get going?</h2>
                      <div class="row">
                        <div className="flex gap-5 ">
                          <p
                            onClick={() => setView("grid")}
                            className={`${
                              view === "grid"
                                ? "flex py-1 md:px-5 px-2 bg-amber-500 text-[#fff]  rounded-md shadow-2xl cursor-pointer small"
                                : "flex py-1 md:px-5 px-2  rounded-md shadow-2xl cursor-pointer bg-white font-bold small gridList text-gray-900"
                            }  `}
                          >
                            Grid View
                          </p>
                          <p
                            onClick={() => setView("list")}
                            className={`${
                              view === "list"
                                ? "flex py-1 md:px-5 px-2 bg-amber-500 text-[#fff]  rounded-md shadow-2xl cursor-pointer small"
                                : "flex py-1 md:px-5 px-2  rounded-md shadow-2xl cursor-pointer bg-white  font-bold small gridList text-gray-900"
                            }  `}
                          >
                            List View
                          </p>
                        </div>
                        {data?.taskdata?.length === 0 ? (
                          <div className="">
                            <Nodata />
                          </div>
                        ) : (
                          <div class="">
                            {view === "list" && (
                              <div style={{ borderRadius: "10px" }}>
                                {data?.taskdata?.map((i) => {
                                  return (
                                    <Accordion
                                      expanded={expanded === i.id}
                                      onChange={handleChange(i.id)}
                                      className="!w-full my-2"
                                      style={{
                                        background: "black",
                                        color: "white",
                                        border: "1px solid #314040",
                                      }}
                                    >
                                      <AccordionSummary
                                        expandIcon={
                                          <ExpandMoreIcon
                                            style={{ color: "white" }}
                                          />
                                        }
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                      >
                                        <div className="flex flex-col">
                                          <div className="flex w-full justify-between items-center">
                                            <div className="flex gap-3 justify-center items-center">
                                              <img
                                                src={
                                                  "https://restapi.aquaeal.in/public/upload/earnfrom/crypto.jpg"
                                                }
                                                lt=""
                                                className="object-contain h-14 w-14"
                                              />
                                              <span>
                                                <span className="flex gap-3  items-center">
                                                  <Typography className="!font-bold !text-2xl">
                                                    {i.taskTitle}
                                                  </Typography>{" "}
                                                  {i.taskComplete && (
                                                    <CheckCircleIcon className="!text-green-500" />
                                                  )}
                                                </span>

                                                <Typography className="!text-sm pl-2 mt-2 text-ellipsis overflow-hidden ">
                                                  {i.taskdescribtion}
                                                </Typography>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionSummary>

                                      <AccordionDetails>
                                        {i.taskComplete ? (
                                          <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                            <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                              Your Task Is Completed
                                            </span>{" "}
                                          </button>
                                        ) : (
                                          <div className="flex w-full justify-between items-center">
                                            {address ? (
                                              <button
                                                onClick={() =>
                                                  openWindow(i.taskContaint)
                                                }
                                                class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                              >
                                                <span class="small2 relative truncate px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  {i.subsubcatename}
                                                </span>
                                              </button>
                                            ) : (
                                              <button
                                                onClick={() => {
                                                  navigator("/login");
                                                  localStorage.setItem(
                                                    "path",
                                                    JSON.stringify(
                                                      "project-task"
                                                    )
                                                  );
                                                }}
                                                class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                              >
                                                <span class="small2 relative truncate px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  {i.subsubcatename}
                                                </span>
                                              </button>
                                            )}
                                            {address ? (
                                              <button
                                                onClick={() => POstTweet(i.id)}
                                                class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                              >
                                                <span class="small2 relative truncate px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  verify
                                                </span>
                                              </button>
                                            ) : (
                                              <button
                                                onClick={() => {
                                                  navigator("/login");
                                                  localStorage.setItem(
                                                    "path",
                                                    JSON.stringify(
                                                      "project-task"
                                                    )
                                                  );
                                                }}
                                                class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                              >
                                                <span class="small2 relative truncate px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  verify
                                                </span>
                                              </button>
                                            )}
                                          </div>
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  );
                                })}
                              </div>
                            )}
                            {view === "grid" && (
                              <div className="row">
                                {data?.taskdata?.map((i) => {
                                  return (
                                    <div className="col-lg-4 col-md-4">
                                      <div className="my-3">
                                        <div className="flex flex-wrap gap-5 proswitch rounded p-3">
                                          {i.taskComplete && (
                                            <Chip
                                              label={`Achieve point ${i.taskpoint} `}
                                              className="flex absolute right-0 !rounded-none top-1 !h-5   !font-bold !text-white !bg-black"
                                              style={{ fontSize: "10px" }}
                                            />
                                          )}
                                          <div className="tskpic">
                                            <img
                                              src={
                                                "https://restapi.aquaeal.in/public/upload/earnfrom/crypto.jpg"
                                              }
                                              className="rounded"
                                              alt="MoC"
                                            />
                                          </div>
                                          <div className="md:basis-2/3">
                                            <h4 className="ovrfl01 cname font-bold">
                                              {i.taskTitle}
                                            </h4>
                                            <p className="small ovrfl02 text-slate-400">
                                              {i.taskdescribtion}
                                            </p>
                                            {i.taskComplete ? (
                                              <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  Your Task Is Completed
                                                </span>
                                              </button>
                                            ) : (
                                              <div className="flex w-full justify-between items-center">
                                                {address ? (
                                                  <button
                                                    onClick={() =>
                                                      openWindow(i.taskContaint)
                                                    }
                                                    class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 relative truncate px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/login");
                                                      localStorage.setItem(
                                                        "path",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 relative truncate px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                )}
                                                {address ? (
                                                  <button
                                                    onClick={() =>
                                                      POstTweet(i.id)
                                                    }
                                                    class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 relative truncate px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      verify
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/login");
                                                      localStorage.setItem(
                                                        "path",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 relative truncate px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      verify
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>

          <Dialog
            open={open1}
            onClose={handleClose}
            PaperProps={{
              className:
                "!lg:w-[25%] !lg:min-h-[40%] px-3 py-3 flex gap-4 flex-col !rounded-xl !text-shadow-none  !bg-[#29303d] ",
            }}
          >
            <div className="flex flex-col w-full ">
              <div className="flex justify-center items-center  ">
                <img
                  src={
                    "https://restapi.aquaeal.in/public/upload/earnfrom/crypto.jpg"
                  }
                  alt=""
                  className="h-20 w-20 rounded-md"
                />
                <div className="flex flex-col">
                  <div className="flex px-3  gap-3">
                    <p className="text-xl text-white small font-bold my-2 ovrfl01">
                      {see?.[0]?.taskTitle}
                    </p>
                  </div>
                  <p className="text-white small2 ovrfl02 px-3">
                    {see?.[0]?.taskdescribtion}
                  </p>
                </div>
              </div>

              <div className="flex h-[100px] bg-black  bg-opacity-50 my-3 p-3 rounded-md">
                <div className="overflow-hidden text-clip text-white font-bold">
                  <p className="small text-white">{see?.[0]?.taskContaint}</p>
                </div>
              </div>
              <div className="flex w-full items-center justify-between">
                <p className="text-xl font-bol text-gray-100 my-2 small">
                  Reward:
                </p>
                <p className="text-xl font-bol text-gray-100 small">
                  {see?.[0]?.taskpoint}
                </p>
              </div>
              {/* <p className="flex text-white font-5xl">{statusX.message}</p> */}
              {see?.[0]?.taskComplete ? (
                <p
                  // onClick={() => POstTweet(see?.[0]?.id)}
                  className="font-bold text-center bg-black   py-2 rounded-md my-3 cursor-pointer text-green-500 "
                >
                  Your Task Is Completed
                </p>
              ) : statusX?.status ? (
                <p
                  // onClick={() => POstTweet(see?.[0]?.id)}
                  className="font-bold text-center bg-black   py-2 rounded-md my-3 cursor-pointer text-green-500 "
                >
                  You Got {statusX?.point} Point
                </p>
              ) : isLoding ? (
                <p className="font-bold text-center  bg-emerald-500 py-2 rounded-md my-3 cursor-pointer hover:bg-black hover:text-white">
                  <CircularProgress />
                </p>
              ) : token ? (
                <p
                  onClick={() => POstTweet(see?.[0]?.id)}
                  className="font-bold text-center  bg-emerald-500 py-2 rounded-md my-3 cursor-pointer hover:bg-black hover:text-white"
                >
                  {see?.[0]?.subsubcatename}
                </p>
              ) : (
                <p
                  onClick={() => {
                    navigator("/login");
                    localStorage.setItem("path", JSON.stringify("webtask"));
                  }}
                  className="font-bold text-center  bg-emerald-500 py-2 rounded-md my-3 cursor-pointer hover:bg-black hover:text-white"
                >
                  {see?.[0]?.subsubcatename}
                </p>
              )}
              <p className="flex text-white font-5xl">{statusX.message}</p>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
