import { Dialog } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import image from "../../Img/tX.png";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import TelegramLogin1 from "../Telegram copy";
import teligram from "../../Img/telegram.png";
import tw from "../../Img/twitter.png";
import you from "../../Img/youtube.png";
import facebook from "../../Img/facebook.png";
import dicord from "../../Img/dicord.png";

export default function Link() {
  const { yopen, open, topen, ddis } = useContext(StakingApp);

  const token = localStorage.getItem("token");

  return (
    <div>
      <Dialog
        open={topen}
        // onClose={handleClose}
        PaperProps={{
          className:
            "!w-[30%] !min-h-[30%] px-3 py-3 flex gap-3 flex-col justify-center items-center !rounded-2xl !text-shadow-none  !bg-gradient-to-t from-cyan-500 to-blue-100 ",
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <img className="h-20 w-20" src={teligram} alt="" />
          <div className="e">
            <TelegramLogin1 />
          </div>
          {/* <a
            href={`https://restapi.aquaeal.in/TwitterAuth/login?UserToken=${token}`}
            target="_blank"
            className="border px-5 py-1 mt-5 rounded-md hover:bg-blue-500"
          >
            Please Link in 
          </a> */}
        </div>
      </Dialog>
      <Dialog
        open={open}
        // onClose={handleClose}
        PaperProps={{
          className:
            "!w-[30%] !min-h-[30%] px-3 py-3 flex gap-3 flex-col justify-center items-center !rounded-2xl !text-shadow-none  !bg-gradient-to-t from-cyan-500 to-blue-100 ",
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <img className="h-20 w-20" src={tw} alt="" />

          <a
            href={`https://restapi.aquaeal.in/TwitterAuth/login?UserToken=${token}`}
            target="_blank"
            className="border px-5 py-1 mt-5 rounded-md hover:bg-blue-500"
          >
            Please Link in X
          </a>
        </div>
      </Dialog>

      <Dialog
        open={yopen}
        // onClose={handleClose}
        PaperProps={{
          className:
            "!w-[30%] !min-h-[30%] px-3 py-3 flex gap-3 flex-col justify-center  !rounded-2xl !text-shadow-none  !bg-gradient-to-t from-red-500 to-red-100 ",
        }}
      >
        <div className="flex justify-end items-end">
          <p>hello</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="h-20 w-20" src={you} alt="" />

          <a
            href={`https://restapi.aquaeal.in/SocialLoginAuth/Youtube?UserToken=${token}`}
            target="_blank"
            className="border px-5 py-1 mt-5 rounded-md hover:bg-blue-500"
          >
            Please Link With You Tube
          </a>
        </div>
      </Dialog>
      <Dialog
        open={ddis}
        // onClose={handleClose}
        PaperProps={{
          className:
            "!w-[30%] !min-h-[30%] px-3 py-3 flex gap-3 flex-col justify-center  !rounded-2xl !text-shadow-none  !bg-gradient-to-t from-blue-500 to-blue-100 ",
        }}
      >
        <div className="flex justify-end items-end">
          <p>hello</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="h-20 w-20" src={dicord} alt="" />

          <a
            href={`https://restapi.aquaeal.in/SocialLoginAuth/Discord?UserToken=${token}`}
            target="_blank"
            className="border px-5 py-1 mt-5 rounded-md hover:bg-blue-500"
          >
            Please Link Dicord
          </a>
        </div>
      </Dialog>
    </div>
  );
}
