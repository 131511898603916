import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance1 from "../../Config/axiosuser";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  CircularProgress,
  Dialog,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import Loder from "../../Pages/Loder";
import Slider from "react-slick";
import { useAccount } from "wagmi";

export default function Coindetail() {
  const { address } = useAccount();
  const token = localStorage.getItem("token");
  const [open1, setOpen1] = useState(false);
  const { theme } = useContext(StakingApp);
  const [data, setData] = useState();
  const id = localStorage.getItem("id2");
  const [see, setSee] = useState();
  const [statusX, setStatusX] = useState({});
  const [isLoding, setIsLoding] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [isLodin, setIsLodin] = useState(false);
  const navigator = useNavigate();
  const [view, setView] = useState("grid");
  const [x, setX] = useState("");
  const [you, setYou] = useState("");
  const [dis, setDis] = useState("");
  const [teli, setTeli] = useState("");
  const openWindow = (id) => {
    const width = 1000;
    const height = 800;
    const leftPosition = (window.screen.width - width) / 2;
    const topPosition = (window.screen.height - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${leftPosition},top=${topPosition},resizable=yes,scrollbars=yes,status=yes`;

    const newWindow = window.open(`${id}`, "_blank", windowFeatures);
    if (newWindow) {
      newWindow.focus();
    } else {
      alert("Popup blocked! Please allow popups for this site.");
    }
  };

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = data?.TaskgroupData?.endtime;

  const timeNow = Date.now();

  const time = Date.parse(deadline) - timeNow;

  const getTime = () => {
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };
  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, [timeNow]);

  useEffect(() => {
    setX(localStorage.getItem("x"));
    setYou(localStorage.getItem("you"));
    setDis(localStorage.getItem("dis"));
    setTeli(localStorage.getItem("teli"));
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => {
    setOpen1(false);
    setSee("");
    setStatusX("");
  };
  const handelopen = (id) => {
    setOpen1(true);
    setSee(data?.taskData?.filter((data) => data.id === id));
  };

  const AddProject = () => {
    setIsLodin(true);
    axiosInstance
      .get(API_URLS.tasklistbytaskgroup + `/${id}`)
      .then((res) => {
        setData(res.data);
        setIsLodin(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLodin(false);
      });
  };

  const POstTweet1 = (id) => {
    setIsLoding(true);
    axiosInstance1
      .post(API_URLS.UserWeb3Task, { taskid: id })
      .then((res) => {
        toast.success(res.data.message);
        setIsLoding(false);

        if (res.data.URL) {
          window.open(`${res.data.URL}`, "_blank");
        }

        setStatusX({
          status: res.data.TaskStatus,
          point: res.data.TaskPoint,
          message: res.data.message,
        });
        AddProject();
      })
      .catch((err) => {
        console.log(err);
        setIsLoding(false);
      });
  };

  const POstTweet = (id) => {
    setIsLoding(true);
    axiosInstance1
      .post(API_URLS.UserSocialAction, { taskid: id })
      .then((res) => {
        toast.success(res.data.message);
        setIsLoding(false);

        if (res.data.videoStatus) {
          openWindow(res.data.actionUrl);
        } else {
          if (res.data.actionUrl) {
            window.open(`${res.data.actionUrl}`, "_blank");
          }
        }

        setStatusX({ status: res.data.TaskStatus, point: res.data.TaskPoint });
        AddProject();
      })
      .catch((err) => {
        console.log(err);
        setIsLoding(false);
      });
  };

  useEffect(() => {
    AddProject();
  }, [id]);

  return (
    <>
      {isLodin ? (
        <Loder />
      ) : (
        <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
          <main id="main">
            <section class="prflbg about innnerpage mt-5">
              <div class="container">
                <div class="section-title">
                  <div class="row justify-content-center">
                    <div class="col-lg-12">
                      <div className="row mb-4">
                        <div className="col-lg-2 col-md-2">
                          {" "}
                          <div class="flex-none cncover2">
                            <img
                              className="rounded-full"
                              src={data?.TaskgroupData?.imagePath}
                              alt="MoC"
                              style={{ maxWidth: "180px" }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                          {" "}
                          <div className="grow">
                            <span className="flex flex-col">
                              <h2>{data?.TaskgroupData?.taskgrouptitle}</h2>
                              <p class=" ">
                                {data?.TaskgroupData?.taskgroupdescribtion}
                              </p>
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div className="border-gray-700 border-2 p-2 rounded text-center">
                            <div className="">
                              <img
                                className="rounded-full"
                                src={data?.TaskgroupData?.imagePath}
                                alt="MoC"
                                style={{
                                  maxWidth: "60px",
                                  margin: "0 auto 10px",
                                }}
                              />
                            </div>
                            <div className="flex-none gap-5  d-flex items-center flex-col">
                              <div className="flex">
                                <p className="h-10 px-2 tmrbox rounded-lg items-center flex justify-center flex-col ">
                                  <span>{days}</span>{" "}
                                  <span className="text-xs block">Days</span>
                                </p>
                                <p className="h-10 px-2 rounded-lg tmrbox items-center flex flex-col justify-center">
                                  {hours}{" "}
                                  <span className="text-xs block">Hours</span>
                                </p>
                                <p className="h-10 px-2 tmrbox rounded-lg items-center flex flex-col justify-center">
                                  {minutes}{" "}
                                  <span className="text-xs block">Min</span>
                                </p>
                                <p className="h-10 px-2 tmrbox rounded-lg items-center flex flex-col justify-center">
                                  {seconds}{" "}
                                  <span className="text-xs block">Sec</span>
                                </p>
                              </div>
                            </div>
                            <p className="small mt-2 ovrfl02">
                              Dive into the Future of Digital Replicas with
                              Sensay! Dive into the Future of Digital Replicas
                            </p>
                          </div>
                        </div>
                      </div>

                      <hr className="my-2" />
                      <h2 class="mt-md-4 my-4">How to get going?</h2>

                      <div class="row">
                        <div className="flex gap-5 ">
                          <p
                            onClick={() => setView("grid")}
                            className={`${
                              view === "grid"
                                ? "flex py-1 md:px-5 px-2 small bg-amber-500 btncolor rounded-md shadow-2xl cursor-pointer"
                                : "flex py-1 md:px-5 px-2 small  rounded-md shadow-2xl cursor-pointer bg-white text-green-950 border"
                            }  `}
                          >
                            Grid View
                          </p>
                          <p
                            onClick={() => setView("list")}
                            className={`${
                              view === "list"
                                ? "flex py-1 md:px-5 px-2 small bg-amber-500 btncolor rounded-md shadow-2xl cursor-pointer"
                                : "flex py-1 md:px-5 px-2 small  rounded-md shadow-2xl cursor-pointer bg-white text-green-950 border"
                            }  `}
                          >
                            List View
                          </p>
                        </div>
                        <div class="">
                          {view === "list" && (
                            <div style={{ borderRadius: "10px" }}>
                              {data?.taskData?.map((i) => {
                                return (
                                  <Accordion
                                    expanded={expanded === i.id}
                                    onChange={handleChange(i.id)}
                                    className="!w-full my-2"
                                    style={{
                                      background: "black",
                                      color: "white",
                                      border: "1px solid #314040",
                                    }}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        <ExpandMoreIcon
                                          style={{ color: "white" }}
                                        />
                                      }
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                    >
                                      <div className="flex flex-col">
                                        <div className="flex w-full justify-between items-center">
                                          <div className="flex gap-3 justify-center items-center">
                                            <img
                                              src={
                                                i.subcategory_id === "1"
                                                  ? "https://restapi.aquaeal.in/public/upload/earnfrom/tw.jpg"
                                                  : i.subcategory_id === "5"
                                                  ? "https://restapi.aquaeal.in/public/upload/earnfrom/youtube.jpg"
                                                  : i.subcategory_id === "8"
                                                  ? "https://restapi.aquaeal.in/public/upload/earnfrom/discord.jpg"
                                                  : i.subcategory_id === "9"
                                                  ? "https://restapi.aquaeal.in/public/upload/earnfrom/teligram.jpg"
                                                  : i.category_id === "3"
                                                  ? "https://restapi.aquaeal.in/public/upload/earnfrom/crypto.jpg"
                                                  : ""
                                              }
                                              className="object-contain h-14 w-14"
                                            />
                                            <span>
                                              <span className="flex gap-3  items-center">
                                                <Typography className="!font-bold !text-2xl">
                                                  {i.taskTitle}
                                                </Typography>{" "}
                                                {i.taskComplete && (
                                                  <CheckCircleIcon className="!text-green-500" />
                                                )}
                                              </span>

                                              <Typography className="!text-sm pl-2 mt-2 text-ellipsis overflow-hidden ">
                                                {i.taskdescribtion}
                                              </Typography>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                    {i.category_id === "1" && (
                                      <AccordionDetails>
                                        <div className="flex justify-between w-full items-center gap-5">
                                          <div className="flex flex-col  ">
                                            {i.subcategory_id === "9" &&
                                              (i.taskComplete ? (
                                                <button class="relative  inline-flex items-center  justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                  <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                    Your Task Is Completed
                                                  </span>
                                                </button>
                                              ) : (
                                                <div className="flex w-full justify-end items-center">
                                                  {!token ? (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/login");
                                                        localStorage.setItem(
                                                          "path",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                        {i.subsubcatename}
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <>
                                                      {teli === "0" ? (
                                                        <button
                                                          onClick={() => {
                                                            navigator(
                                                              "/profile"
                                                            );
                                                            localStorage.setItem(
                                                              "tab",
                                                              2
                                                            );
                                                            localStorage.setItem(
                                                              "taskPath",
                                                              JSON.stringify(
                                                                "project-task"
                                                              )
                                                            );
                                                          }}
                                                          class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          onClick={() =>
                                                            openWindow(
                                                              `https://t.me/${i.taskContaint}`
                                                            )
                                                          }
                                                          class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      )}
                                                    </>
                                                  )}

                                                  {teli === "0" ? (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/profile");
                                                        localStorage.setItem(
                                                          "tab",
                                                          2
                                                        );
                                                        localStorage.setItem(
                                                          "taskPath",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                        Verify
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <button
                                                      onClick={() =>
                                                        POstTweet(i.id)
                                                      }
                                                      class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                        Verify
                                                      </span>
                                                    </button>
                                                  )}
                                                </div>
                                              ))}
                                            {i.subcategory_id === "8" &&
                                              (i.taskComplete ? (
                                                <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                  <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                    Your Task Is Completed
                                                  </span>
                                                </button>
                                              ) : (
                                                <div className="flex w-full justify-end items-center gap-5">
                                                  {!token ? (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/login");
                                                        localStorage.setItem(
                                                          "path",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                        {i.subsubcatename}
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <>
                                                      {dis === "0" ? (
                                                        <button
                                                          onClick={() => {
                                                            navigator(
                                                              "/profile"
                                                            );
                                                            localStorage.setItem(
                                                              "tab",
                                                              2
                                                            );
                                                            localStorage.setItem(
                                                              "taskPath",
                                                              JSON.stringify(
                                                                "project-task"
                                                              )
                                                            );
                                                          }}
                                                          class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          onClick={() =>
                                                            openWindow(
                                                              `https://discord.com/invite/${i.taskContaint}`
                                                            )
                                                          }
                                                          class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      )}
                                                    </>
                                                  )}

                                                  {dis === "0" ? (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/profile");
                                                        localStorage.setItem(
                                                          "tab",
                                                          2
                                                        );
                                                        localStorage.setItem(
                                                          "taskPath",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                        Verify
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <button
                                                      onClick={() =>
                                                        POstTweet(i.id)
                                                      }
                                                      class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                        Verify
                                                      </span>
                                                    </button>
                                                  )}
                                                </div>
                                              ))}
                                            {i.subsubcatename === "Like" &&
                                              i.subcategoryName ===
                                                "Youtube" && (
                                                <div className="flex w-full justify-between items-center gap-5">
                                                  <Typography>
                                                    Please {i.subsubcatename} on{" "}
                                                    {i.subcategoryName} this
                                                    video "
                                                    <b className="text-blue-500">
                                                      {i.taskContaint}
                                                    </b>
                                                    "
                                                  </Typography>
                                                  {you === "0" ? (
                                                    <p
                                                      onClick={() =>
                                                        navigator("/profile")
                                                      }
                                                      className="text-blue-500 font-bold px-3 py-2 border cursor-pointer rounded-xl ovrfl01"
                                                    >
                                                      {i.subsubcatename} And
                                                      Earn Point {i.taskpoint}
                                                    </p>
                                                  ) : (
                                                    <p
                                                      onClick={() =>
                                                        handelopen(i.id)
                                                      }
                                                      className="text-blue-500 font-bold px-3 py-2 border cursor-pointer rounded-xl ovrfl01"
                                                    >
                                                      {i.subsubcatename} And
                                                      Earn Point {i.taskpoint}
                                                    </p>
                                                  )}
                                                </div>
                                              )}
                                            {i.subsubcatename ===
                                              "View Video" && (
                                              <div className="flex w-full justify-between items-center gap-5">
                                                <Typography>
                                                  Please {i.subsubcatename} on{" "}
                                                  {/* {i.subcategoryName} on this
                                                  Profile "
                                                  <b className="text-blue-500">
                                                    {i.taskContaint}
                                                  </b> */}
                                                </Typography>
                                                {you === "0" ? (
                                                  <p
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    className="text-blue-500 font-bold px-3 py-2 border cursor-pointer rounded-xl ovrfl01"
                                                  >
                                                    {i.subsubcatename} And Earn
                                                    Point {i.taskpoint}
                                                  </p>
                                                ) : (
                                                  <p
                                                    onClick={() =>
                                                      handelopen(i.id)
                                                    }
                                                    className="text-blue-500 font-bold px-3 py-2 border cursor-pointer rounded-xl ovrfl01"
                                                  >
                                                    {i.subsubcatename} And Earn
                                                    Point {i.taskpoint}
                                                  </p>
                                                )}
                                              </div>
                                            )}
                                            {i.subsubcatename === "Follow" && (
                                              <div className="flex w-full justify-end items-center gap-5">
                                                {i.subsubcate_id === "3" &&
                                                i.taskComplete ? (
                                                  <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                    <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      Your Task Is Completed
                                                    </span>
                                                  </button>
                                                ) : !token ? (
                                                  x === "0" ? (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/profile");
                                                        localStorage.setItem(
                                                          "tab",
                                                          2
                                                        );
                                                        localStorage.setItem(
                                                          "taskPath",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                        Verify
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/login");
                                                        localStorage.setItem(
                                                          "path",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                        {i.subsubcatename}
                                                      </span>
                                                    </button>
                                                  )
                                                ) : (
                                                  <>
                                                    {x === "0" ? (
                                                      <button
                                                        onClick={() => {
                                                          navigator("/profile");
                                                          localStorage.setItem(
                                                            "tab",
                                                            2
                                                          );
                                                          localStorage.setItem(
                                                            "taskPath",
                                                            JSON.stringify(
                                                              "project-task"
                                                            )
                                                          );
                                                        }}
                                                        class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                      >
                                                        <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                          {i.subsubcatename}
                                                        </span>
                                                      </button>
                                                    ) : (
                                                      <button
                                                        onClick={() =>
                                                          openWindow(
                                                            `https://x.com/intent/user?screen_name=${i.taskContaint}`
                                                          )
                                                        }
                                                        class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                      >
                                                        <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                          {i.subsubcatename}
                                                        </span>
                                                      </button>
                                                    )}
                                                  </>
                                                )}

                                                {x === "0" ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      handelopen(i.id)
                                                    }
                                                    class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                            {i.subsubcatename === "Tweet" && (
                                              <div className="flex w-full justify-end items-center gap-5">
                                                {i.subsubcate_id === "1" &&
                                                i.taskComplete ? (
                                                  <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                    <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      Your Task Is Completed
                                                    </span>
                                                  </button>
                                                ) : !token ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/login");
                                                      localStorage.setItem(
                                                        "path",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <>
                                                    {x === "0" ? (
                                                      <button
                                                        onClick={() => {
                                                          navigator("/profile");
                                                          localStorage.setItem(
                                                            "tab",
                                                            2
                                                          );
                                                          localStorage.setItem(
                                                            "taskPath",
                                                            JSON.stringify(
                                                              "project-task"
                                                            )
                                                          );
                                                        }}
                                                        class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                      >
                                                        <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                          {i.subsubcatename}
                                                        </span>
                                                      </button>
                                                    ) : (
                                                      <button
                                                        onClick={() =>
                                                          openWindow(
                                                            `https://x.com/intent/post?text=${i.taskContaint}`
                                                          )
                                                        }
                                                        class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                      >
                                                        <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                          {i.subsubcatename}
                                                        </span>
                                                      </button>
                                                    )}
                                                  </>
                                                )}
                                                {x === "0" ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      handelopen(i.id)
                                                    }
                                                    class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                            {i.subsubcatename === "Like" &&
                                              i.subcategoryName ===
                                                "Twitter-X" && (
                                                <div className="flex w-full justify-end items-center gap-5">
                                                  {i.subsubcate_id === "9" &&
                                                  i.taskComplete ? (
                                                    <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                      <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                        Your Task Is Completed
                                                      </span>
                                                    </button>
                                                  ) : !token ? (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/login");
                                                        localStorage.setItem(
                                                          "path",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                        {i.subsubcatename}
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <>
                                                      {x === "0" ? (
                                                        <button
                                                          onClick={() => {
                                                            navigator(
                                                              "/profile"
                                                            );
                                                            localStorage.setItem(
                                                              "tab",
                                                              2
                                                            );
                                                            localStorage.setItem(
                                                              "taskPath",
                                                              JSON.stringify(
                                                                "project-task"
                                                              )
                                                            );
                                                          }}
                                                          class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          onClick={() =>
                                                            openWindow(
                                                              `https://x.com/intent/like?tweet_id=${i.taskContaint}`
                                                            )
                                                          }
                                                          class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      )}
                                                    </>
                                                  )}

                                                  {x === "0" ? (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/profile");
                                                        localStorage.setItem(
                                                          "tab",
                                                          2
                                                        );
                                                        localStorage.setItem(
                                                          "taskPath",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                        Verify
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <button
                                                      onClick={() =>
                                                        handelopen(i.id)
                                                      }
                                                      class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                        Verify
                                                      </span>
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                            {i.subsubcatename ===
                                              "Re Tweet" && (
                                              <div className="flex w-full justify-end items-center gap-5">
                                                {i.subsubcate_id === "2" &&
                                                i.taskComplete ? (
                                                  <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                    <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      Your Task Is Completed
                                                    </span>
                                                  </button>
                                                ) : !token ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/login");
                                                      localStorage.setItem(
                                                        "path",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <>
                                                    {x === "0" ? (
                                                      <button
                                                        onClick={() => {
                                                          navigator("/profile");
                                                          localStorage.setItem(
                                                            "tab",
                                                            2
                                                          );
                                                          localStorage.setItem(
                                                            "taskPath",
                                                            JSON.stringify(
                                                              "project-task"
                                                            )
                                                          );
                                                        }}
                                                        class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                      >
                                                        <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                          {i.subsubcatename}
                                                        </span>
                                                      </button>
                                                    ) : (
                                                      <button
                                                        onClick={() =>
                                                          openWindow(
                                                            `https://x.com/intent/retweet?tweet_id=${i.taskContaint}`
                                                          )
                                                        }
                                                        class="relative w-[100%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                      >
                                                        <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                          {i.subsubcatename}
                                                        </span>
                                                      </button>
                                                    )}
                                                  </>
                                                )}

                                                {x === "0" ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      handelopen(i.id)
                                                    }
                                                    class="relative w-32 inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </AccordionDetails>
                                    )}
                                    {i.category_id === "3" && (
                                      <AccordionDetails>
                                        {i.taskComplete ? (
                                          <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                            <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                              Your Task Is Completed
                                            </span>{" "}
                                          </button>
                                        ) : (
                                          <div className="flex w-full justify-end items-center gap-5">
                                            {address ? (
                                              <button
                                                onClick={() =>
                                                  openWindow(i.taskContaint)
                                                }
                                                class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                              >
                                                <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  {i.subsubcatename}
                                                </span>
                                              </button>
                                            ) : (
                                              <button
                                                onClick={() => {
                                                  navigator("/login");
                                                  localStorage.setItem(
                                                    "path",
                                                    JSON.stringify(
                                                      "project-task"
                                                    )
                                                  );
                                                }}
                                                class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                              >
                                                <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  {i.subsubcatename}
                                                </span>
                                              </button>
                                            )}

                                            {address === "0" ? (
                                              <button
                                                onClick={() => {
                                                  navigator("/profile");
                                                  localStorage.setItem(
                                                    "tab",
                                                    2
                                                  );
                                                  localStorage.setItem(
                                                    "taskPath",
                                                    JSON.stringify(
                                                      "project-task"
                                                    )
                                                  );
                                                }}
                                                class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                              >
                                                <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                  Verify
                                                </span>
                                              </button>
                                            ) : (
                                              <button
                                                onClick={() => POstTweet1(i.id)}
                                                class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                              >
                                                <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                  Verify
                                                </span>
                                              </button>
                                            )}
                                          </div>
                                        )}
                                      </AccordionDetails>
                                    )}
                                  </Accordion>
                                );
                              })}
                            </div>
                          )}
                          {view === "grid" && (
                            <div className="row">
                              {data?.taskData?.map((i) => {
                                return (
                                  <div className="col-lg-4 col-md-4">
                                    {i.subcategory_id === "9" && (
                                      <div className="my-3">
                                        <div className="flex relative gap-5 proswitch rounded p-3 bg-opacity-75">
                                          <div className="flex absolute top-0 left-0 w-full items-center justify-end">
                                            <Chip
                                              label={`${i.taskpoint}P `}
                                              className={`flex  !rounded-none !h-5 !font-bold !rounded-bl-lg  !text-white ${
                                                i.taskComplete
                                                  ? "!bg-green-500"
                                                  : "!bg-[#F59E0B]"
                                              } `}
                                              style={{ fontSize: "15px" }}
                                            />
                                          </div>
                                          <div className="tskpic">
                                            <img
                                              src={
                                                "https://restapi.aquaeal.in/public/upload/earnfrom/teligram.jpg"
                                              }
                                              className="rounded-full"
                                              alt="MoC"
                                            />
                                          </div>
                                          <div className="basis-2/3">
                                            <h4 className="ovrfl01 cname font-bold">
                                              {i.taskTitle}
                                            </h4>
                                            <p className="small ovrfl02 ">
                                              {i.taskdescribtion}
                                            </p>
                                            {i.taskComplete ? (
                                              <button class="relative inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-gradient-to-br from-green-400 to-blue-900 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:outline-none ">
                                                <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  Your Task Is Completed
                                                </span>
                                              </button>
                                            ) : (
                                              <div className="flex w-full justify-between items-center">
                                                {!token ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/login");
                                                      localStorage.setItem(
                                                        "path",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                ) : teli === "0" ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      openWindow(
                                                        `https://t.me/${i.taskContaint}`
                                                      )
                                                    }
                                                    class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                )}

                                                {teli === "0" ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      POstTweet(i.id)
                                                    }
                                                    class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {i.subcategory_id === "8" && (
                                      <div className="my-3">
                                        <div className="flex relative gap-5 rounded p-3 proswitch  bg-opacity-75">
                                          <div className="flex absolute top-0 left-0 w-full items-center justify-end">
                                            <Chip
                                              label={`${i.taskpoint}P `}
                                              className={`flex  !rounded-none !h-5 !font-bold !rounded-bl-lg  !text-white ${
                                                i.taskComplete
                                                  ? "!bg-green-500"
                                                  : "!bg-[#F59E0B]"
                                              } `}
                                              style={{ fontSize: "15px" }}
                                            />
                                          </div>

                                          <div className="tskpic">
                                            <img
                                              src={
                                                "https://restapi.aquaeal.in/public/upload/earnfrom/discord.jpg"
                                              }
                                              className="rounded"
                                              alt="MoC"
                                            />
                                            {/* <div className="w-5 h-5 bg-white">
                                              hello
                                            </div> */}
                                          </div>
                                          <div className="basis-2/3">
                                            <h4 className="ovrfl01 cname font-bold">
                                              {i.taskTitle}
                                            </h4>
                                            <p className="small ovrfl02 ">
                                              {i.taskdescribtion}
                                            </p>
                                            {i.taskComplete ? (
                                              <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  Your Task Is Completed
                                                </span>
                                              </button>
                                            ) : (
                                              <div className="flex w-full justify-end items-center gap-5">
                                                {!token ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/login");
                                                      localStorage.setItem(
                                                        "path",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                ) : dis === "0" ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      openWindow(
                                                        `https://discord.com/invite/${i.taskContaint}`
                                                      )
                                                    }
                                                    class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                )}

                                                {dis === "0" ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      POstTweet(i.id)
                                                    }
                                                    class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {i.subcategory_id === "5" && (
                                      <div className="my-3">
                                        <div className="flex gap-5 relative  rounded p-3 proswitch bg-opacity-75">
                                          <div className="flex absolute top-0 left-0 w-full items-center justify-end">
                                            <Chip
                                              label={`${i.taskpoint}P `}
                                              className={`flex  !rounded-none !h-5 !font-bold !rounded-bl-lg  !text-white ${
                                                i.taskComplete
                                                  ? "!bg-green-500"
                                                  : "!bg-[#F59E0B]"
                                              } `}
                                              style={{ fontSize: "15px" }}
                                            />
                                          </div>
                                          <div className="tskpic">
                                            <img
                                              src={
                                                "https://restapi.aquaeal.in/public/upload/earnfrom/youtube.jpg"
                                              }
                                              className="rounded"
                                              alt="MoC"
                                            />
                                          </div>
                                          <div className="basis-2/3">
                                            <h4 className="ovrfl01 cname font-bold">
                                              {i.taskTitle}
                                            </h4>
                                            <p className="small ovrfl02 ">
                                              {i.taskdescribtion}
                                            </p>
                                            {i.subsubcatename ===
                                            "View Video" ? (
                                              i.taskComplete ? (
                                                <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                  <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                    Your Task Is Completed
                                                  </span>
                                                </button>
                                              ) : you === "0" ? (
                                                <button
                                                  onClick={() => {
                                                    navigator("/profile");
                                                    localStorage.setItem(
                                                      "tab",
                                                      2
                                                    );
                                                    localStorage.setItem(
                                                      "taskPath",
                                                      JSON.stringify(
                                                        "project-task"
                                                      )
                                                    );
                                                  }}
                                                  class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                                >
                                                  <span class="small2 relative px-2 truncate py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                    {i.subsubcatename} And Earn
                                                    Point {i.taskpoint}
                                                  </span>
                                                </button>
                                              ) : (
                                                <button
                                                  onClick={() =>
                                                    handelopen(i.id)
                                                  }
                                                  class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                                >
                                                  <span class="small2 relative truncate px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                    {i.subsubcatename} And Earn
                                                    Point {i.taskpoint}
                                                  </span>
                                                </button>
                                              )
                                            ) : i.taskComplete ? (
                                              <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  Your Task Is Completed
                                                </span>
                                              </button>
                                            ) : (
                                              <>
                                                {you === "0" ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 relative px-2 truncate py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename} And
                                                      Earn Point {i.taskpoint}
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      handelopen(i.id)
                                                    }
                                                    class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 relative px-2 truncate py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename} And
                                                      Earn Point {i.taskpoint}
                                                    </span>
                                                  </button>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {i.subcategory_id === "1" && (
                                      <div className="my-3">
                                        <div className="flex gap-5 relative rounded p-3 proswitch bg-opacity-75">
                                          <div className="flex absolute top-0 left-0 w-full items-center justify-end">
                                            <Chip
                                              label={`${i.taskpoint}P `}
                                              className={`flex  !rounded-none !h-5 !font-bold !rounded-bl-lg  !text-white ${
                                                i.taskComplete
                                                  ? "!bg-green-500"
                                                  : "!bg-[#F59E0B]"
                                              } `}
                                              style={{ fontSize: "15px" }}
                                            />
                                          </div>
                                          <div className="tskpic">
                                            <img
                                              src={
                                                "https://restapi.aquaeal.in/public/upload/earnfrom/tw.jpg"
                                              }
                                              className="rounded"
                                              alt="MoC"
                                            />
                                          </div>
                                          <div className="basis-2/3">
                                            <h4 className="ovrfl01 cname font-bold">
                                              {i.taskTitle}
                                            </h4>
                                            <p className="small ovrfl02 ">
                                              {i.taskdescribtion}
                                            </p>
                                            {i.taskComplete ? (
                                              <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  Your Task Is Completed
                                                </span>
                                              </button>
                                            ) : (
                                              <div className="flex w-full justify-between items-center">
                                                {i.subsubcate_id === "9" &&
                                                  (token ? (
                                                    <>
                                                      {x === "0" ? (
                                                        <button
                                                          onClick={() => {
                                                            navigator(
                                                              "/profile"
                                                            );
                                                            localStorage.setItem(
                                                              "tab",
                                                              2
                                                            );
                                                            localStorage.setItem(
                                                              "taskPath",
                                                              JSON.stringify(
                                                                "project-task"
                                                              )
                                                            );
                                                          }}
                                                          class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          onClick={() =>
                                                            openWindow(
                                                              `https://x.com/intent/like?tweet_id=${i.taskContaint}`
                                                            )
                                                          }
                                                          class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/login");
                                                        localStorage.setItem(
                                                          "path",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                        {i.subsubcatename}
                                                      </span>
                                                    </button>
                                                  ))}
                                                {i.subsubcate_id === "3" &&
                                                  (token ? (
                                                    <>
                                                      {x === "0" ? (
                                                        <button
                                                          onClick={() => {
                                                            navigator(
                                                              "/profile"
                                                            );
                                                            localStorage.setItem(
                                                              "tab",
                                                              2
                                                            );
                                                            localStorage.setItem(
                                                              "taskPath",
                                                              JSON.stringify(
                                                                "project-task"
                                                              )
                                                            );
                                                          }}
                                                          class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          onClick={() =>
                                                            openWindow(
                                                              `https://x.com/intent/user?screen_name=${i.taskContaint}`
                                                            )
                                                          }
                                                          class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/login");
                                                        localStorage.setItem(
                                                          "path",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                        {i.subsubcatename}
                                                      </span>
                                                    </button>
                                                  ))}
                                                {i.subsubcate_id === "2" &&
                                                  (token ? (
                                                    <>
                                                      {x === "0" ? (
                                                        <button
                                                          onClick={() => {
                                                            navigator(
                                                              "/profile"
                                                            );
                                                            localStorage.setItem(
                                                              "tab",
                                                              2
                                                            );
                                                            localStorage.setItem(
                                                              "taskPath",
                                                              JSON.stringify(
                                                                "project-task"
                                                              )
                                                            );
                                                          }}
                                                          class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          onClick={() =>
                                                            openWindow(
                                                              `https://x.com/intent/retweet?tweet_id=${i.taskContaint}`
                                                            )
                                                          }
                                                          class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/login");
                                                        localStorage.setItem(
                                                          "path",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                        {i.subsubcatename}
                                                      </span>
                                                    </button>
                                                  ))}
                                                {i.subsubcate_id === "1" &&
                                                  (token ? (
                                                    <>
                                                      {x === "0" ? (
                                                        <button
                                                          onClick={() => {
                                                            navigator(
                                                              "/profile"
                                                            );
                                                            localStorage.setItem(
                                                              "tab",
                                                              2
                                                            );
                                                            localStorage.setItem(
                                                              "taskPath",
                                                              JSON.stringify(
                                                                "project-task"
                                                              )
                                                            );
                                                          }}
                                                          class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          onClick={() =>
                                                            openWindow(
                                                              `https://x.com/intent/post?text=${i.taskContaint}`
                                                            )
                                                          }
                                                          class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                        >
                                                          <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                            {i.subsubcatename}
                                                          </span>
                                                        </button>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <button
                                                      onClick={() => {
                                                        navigator("/login");
                                                        localStorage.setItem(
                                                          "path",
                                                          JSON.stringify(
                                                            "project-task"
                                                          )
                                                        );
                                                      }}
                                                      class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                    >
                                                      <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                        {i.subsubcatename}
                                                      </span>
                                                    </button>
                                                  ))}

                                                {x === "0" ? (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/profile");
                                                      localStorage.setItem(
                                                        "tab",
                                                        2
                                                      );
                                                      localStorage.setItem(
                                                        "taskPath",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      POstTweet(i.id)
                                                    }
                                                    class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {i.category_id === "3" && (
                                      <div className="my-3">
                                        <div className="flex gap-5 relative  rounded p-3 proswitch  bg-opacity-75">
                                          <div className="flex absolute top-0 left-0 w-full items-center justify-end">
                                            <Chip
                                              label={`${i.taskpoint}P `}
                                              className={`flex  !rounded-none !h-5 !font-bold !rounded-bl-lg  !text-white ${
                                                i.taskComplete
                                                  ? "!bg-green-500"
                                                  : "!bg-[#F59E0B]"
                                              } `}
                                              style={{ fontSize: "15px" }}
                                            />
                                          </div>
                                          <div className="tskpic">
                                            <img
                                              src={
                                                "https://restapi.aquaeal.in/public/upload/earnfrom/crypto.jpg"
                                              }
                                              className="rounded"
                                              alt="MoC"
                                            />
                                          </div>
                                          <div className="basis-2/3">
                                            <h4 className="ovrfl01 cname font-bold">
                                              {i.taskTitle}
                                            </h4>
                                            <p className="small ovrfl02 ">
                                              {i.taskdescribtion}
                                            </p>
                                            {i.taskComplete ? (
                                              <button class="relative  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-amber-500">
                                                <span class="small2 relative px-2 py-2 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                  Your Task Is Completed
                                                </span>{" "}
                                              </button>
                                            ) : (
                                              <div className="flex w-full justify-between items-center">
                                                {address ? (
                                                  <button
                                                    onClick={() =>
                                                      openWindow(i.taskContaint)
                                                    }
                                                    class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/login");
                                                      localStorage.setItem(
                                                        "path",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[50%]  inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-1 overflow-hidden text-mb text-white rounded-lg group bg-amber-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-black dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                                      {i.subsubcatename}
                                                    </span>
                                                  </button>
                                                )}
                                                {address ? (
                                                  <button
                                                    onClick={() =>
                                                      POstTweet1(i.id)
                                                    }
                                                    class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() => {
                                                      navigator("/login");
                                                      localStorage.setItem(
                                                        "path",
                                                        JSON.stringify(
                                                          "project-task"
                                                        )
                                                      );
                                                    }}
                                                    class="relative w-[50%] inline-flex items-center justify-center mt-2 p-0.5 mb-2 me-2 overflow-hidden text-sm font-bold text-black rounded-lg group bg-gray-500"
                                                  >
                                                    <span class="small2 w-full relative px-2 truncate py-1 transition-all ease-in duration-75 bg-gray-200  font-bold rounded-md hover:bg-amber-500 hover:text-white">
                                                      Verify
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>

          <Dialog
            open={open1}
            onClose={handleClose}
            PaperProps={{
              className:
                "!lg:w-[25%] !w-[25%] !lg:min-h-[40%] px-3 py-3 flex gap-4 flex-col !rounded-xl !text-shadow-none  !bg-[#29303d] ",
            }}
          >
            <div className="flex flex-col w-full ">
              <div className="flex  items-center  ">
                <img
                  src={
                    see?.[0]?.subcategory_id === "1"
                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/tw.jpg"
                      : see?.[0]?.subcategory_id === "5"
                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/youtube.jpg"
                      : see?.[0]?.subcategory_id === "8"
                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/discord.jpg"
                      : see?.[0]?.subcategory_id === "9"
                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/teligram.jpg"
                      : see?.[0]?.category_id === "3"
                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/crypto.jpg"
                      : ""
                  }
                  alt=""
                  className="h-20 w-20 rounded-md"
                />
                <div className="flex flex-col">
                  <div className="flex px-3  gap-3">
                    <p className="text-xl text-white small font-bold my-2 ovrfl01">
                      {see?.[0]?.taskTitle}
                    </p>
                  </div>
                  <p className="text-white small2 ovrfl02 px-3">
                    {see?.[0]?.taskdescribtion}
                  </p>
                </div>
              </div>

              <div className="flex h-[150px] bg-black  bg-opacity-50 my-3 p-3 rounded-md ">
                <div className="overflow-hidden text-clip text-white font-bold overflow-y-auto">
                  <p className="small text-white">{see?.[0]?.taskContaint}</p>
                </div>
              </div>
              <div className="flex w-full items-center justify-between">
                <p className="text-xl font-bol text-gray-100 my-2">Reward:</p>
                <p className="text-xl font-bol text-gray-100">
                  {see?.[0]?.taskpoint}
                </p>
              </div>
              <p className="flex text-white font-5xl">{statusX.message}</p>

              {see?.[0]?.subcategoryName === "Web3" ? (
                see?.[0]?.taskComplete ? (
                  <p
                    // onClick={() => POstTweet(see?.[0]?.id)}
                    className="font-bold text-center bg-black  py-2 rounded-md my-3 cursor-pointer text-green-500 "
                  >
                    Your Task Is Completed
                  </p>
                ) : statusX?.status ? (
                  <p
                    // onClick={() => POstTweet(see?.[0]?.id)}
                    className="font-bold text-center bg-black   py-2 rounded-md my-3 cursor-pointer text-green-500 "
                  >
                    You Got {statusX?.point} Point
                  </p>
                ) : isLoding ? (
                  <p className="font-bold text-center  bg-emerald-500 py-2 rounded-md my-3 cursor-pointer hover:bg-black hover:text-white">
                    <CircularProgress />
                  </p>
                ) : token ? (
                  <p
                    onClick={() => POstTweet1(see?.[0]?.id)}
                    className="font-bold text-center  bg-emerald-500 text-white py-2 rounded-md my-3 cursor-pointer hover:bg-black hover:text-white"
                  >
                    {see?.[0]?.subsubcatename} And Earn Point{" "}
                    {see?.[0]?.taskpoint}
                  </p>
                ) : (
                  <p
                    onClick={() => navigator("/login")}
                    className="font-bold text-center  bg-emerald-500 text-white py-2 rounded-md my-3 cursor-pointer hover:bg-black hover:text-white"
                  >
                    {see?.[0]?.subsubcatename} And Earn Point{" "}
                    {see?.[0]?.taskpoint}
                  </p>
                )
              ) : see?.[0]?.taskComplete ? (
                <p
                  // onClick={() => POstTweet(see?.[0]?.id)}
                  className="font-bold text-center bg-black   py-2 rounded-md my-3 cursor-pointer text-green-500 "
                >
                  Your Task Is Completed
                </p>
              ) : statusX?.status ? (
                <p
                  // onClick={() => POstTweet(see?.[0]?.id)}
                  className="font-bold text-center bg-black   py-2 rounded-md my-3 cursor-pointer text-green-500 "
                >
                  You Got {statusX?.point} Point
                </p>
              ) : isLoding ? (
                <p className="font-bold text-center  bg-emerald-500 text-white py-2 rounded-md my-3 cursor-pointer hover:bg-black hover:text-white">
                  <CircularProgress />
                </p>
              ) : token ? (
                <p
                  onClick={() => POstTweet(see?.[0]?.id)}
                  className="font-bold text-center  bg-emerald-500 text-white py-2 rounded-md my-3 cursor-pointer hover:bg-black hover:text-white"
                >
                  {see?.[0]?.subsubcatename} And Earn Point{" "}
                  {see?.[0]?.taskpoint}
                </p>
              ) : (
                <p
                  onClick={() => {
                    navigator("/login");
                    localStorage.setItem("path", JSON.stringify("coindetail"));
                  }}
                  className="font-bold text-center  bg-emerald-500 text-white py-2 rounded-md my-3 cursor-pointer hover:bg-black hover:text-white"
                >
                  {see?.[0]?.subsubcatename} And Earn Point{" "}
                  {see?.[0]?.taskpoint}
                </p>
              )}
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
