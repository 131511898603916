import React from "react";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { bsc, bscTestnet, mainnet } from "viem/chains";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Influencer from "./Components/Admindashborde/Influencer";
import { Staking } from "./Components/UserDasbord/StateMengmanet";
import Link from "./Components/Admindashborde/link";
import routes from "./Component/Router";
import Layout from "./Component/Layout";
import Page404 from "./Components/Pages/page404";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
function App() {
  const projectId = "0345c7230f52356034d20c7c5fbf2519";
  // 2. Create wagmiConfig
  const metadata = {
    name: "mOC",
    description: "Welcome to POC, It is future of innovation and finance",
    url: "https://puremint.io/",
    icons: ["https://moc.io/logo/Puremint-logo.png"],
  };

  const chains = [bsc, bscTestnet, mainnet];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

  // 3. Create modal
  createWeb3Modal({ wagmiConfig, projectId, chains });
  return (
    <WagmiConfig config={wagmiConfig}>
      <GoogleOAuthProvider clientId="237140750449-9pmlu22ju3h73l0ehl6gjruum0ahniaf.apps.googleusercontent.com">
        <Staking>
          <BrowserRouter>
            <Link />
            <Routes>
              <Route path="/" element={<Influencer />} />
              {routes.map((route) => {
                return (
                  <Route
                    key={route.id}
                    path={route.path}
                    element={<Layout component={route.element} />}
                  />
                );
              })}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </BrowserRouter>
        </Staking>
        {/* <GoogleLogin
          onSuccess={(credentialResponse) => {
            console.log(credentialResponse);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        /> */}
      </GoogleOAuthProvider>
      <ToastContainer />
    </WagmiConfig>
  );
}

export default App;
