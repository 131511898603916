import React, { useContext, useEffect, useState } from "react";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import axiosInstance1 from "../../Config/axiosuser";
import { API_URLS } from "../../Config/apiUrls";
import { Token } from "@mui/icons-material";
import { toast } from "react-toastify";

export default function Leaderboard() {
  const { theme } = useContext(StakingApp);
  const [data, setData] = useState();
  const onCopyLink = (id) => {
    navigator?.clipboard?.writeText(id);
    toast.success(`Copy ${id}`);
    // setcopy(id);
  };
  const refalarlink =
    "https://website.aquaeal.in/" + `?Referral=` + `${data?.yourReferralcode}`;
  const AddProject = () => {
    axiosInstance1
      .get(API_URLS.getDashboard)
      .then((res) => {
        setData(res.data.userdata);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    Token && AddProject();
  });

  return (
    <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
      <main id="main">
        <section class="prflbg about innnerpage">
          <div className="flex flex-col px-10 ">
            <div className="lg:flex justify-center w-full items-center">
        
              <div className="flex flex-col items-center  lg:w-[60%]">
                <span>
                  <h3 className="text-3xl lg:my-0 mt-5">Refer a friend and,</h3>
                  <h2 className="text-4xl mt-2 text-yellow-500 font-bold"> Earn rewards!</h2>
                </span>
                <h2 className="lg:w-[80%] lg:px-0 px-2 md:px-3 my-3">
                  Share the love! Get Quest completions from referrals, Collect
                  Gems, and Redeem Epic Rewards! 🏆 💰
                </h2>
                <div className="lg:flex md:px-5 px-1  gap-5">
                  <div className="flex lg:w-[50%] flex-col justify-start my-2 border-gray-700 border-2 px-3 md:px-5 py-2 rounded-xl">
                    <span className="flex w-full justify-between items-center px-2 ">
                    <h4><i className="bx bx-trophy text-yellow-400 text-4xl"></i></h4>
                      <p className="text-2xl font-bold">1</p>
                    </span>
                    <h2 className="my-2">Earn Rewards</h2>
                    <h1 className="text-sm">
                      Share your unique referral link with a friend.
                    </h1>
                  </div>
                  <div className="flex lg:w-[50%] flex-col justify-start  my-2 border-gray-700 border-2 px-3 md:px-5 py-2 rounded-xl">
                    <span className="flex w-full justify-between items-center px-2 ">
                    <h4><i className="bx bx-trophy text-yellow-400 text-4xl"></i></h4>
                      <p className="text-2xl font-bold">2</p>
                    </span>
                    <h2 className="my-2">Earn Rewards</h2>
                    <h1 className="text-sm">
                      Get 5 gems per new user who signs up and completes their
                      first quest through your link.
                    </h1>
                  </div>
                  
                </div>
                <div className=" lg:flex w-[90%] justify-center items-center py-2 gap-3">
                  <span className="flex lg:w-[80%] justify-center items-center gap-5  py-2 lg:px-0 px-2 border-gray-700 border-2 rounded-lg break-all">
                    <p>{refalarlink}</p>
                    <h2 onClick={() => onCopyLink(refalarlink)} className="text-blue-500 cursor-pointer">
                    <i className="bx bx-copy text-yellow-500"></i>
                    </h2>
                  </span>
                  <span className="flex lg:w-[20%] md:w-[100%] justify-center items-center gap-2 ml-auto bg-amber-500  pl01 lg:my-10 my-2 py-2 text-lg">
                  <p>Share</p></span>
                </div>
              </div>
              <div className="flex  justify-center items-center lg:w-[25%] lg:block">
              <img src="assets/img/refer.png" className="img-fluid" alt="MoC" />
              </div>
            </div>
            <div className="flex flex-col md:px-3 lg:px-44 my-5">
              <div className="flex w-full justify-between items-center my-3">
                <h1 className="text-2xl">Leaderboard</h1>
                </div>
              <div className="flex flex-col w-full proswitch bg-opacity-20 lg:p-5 p-3 rounded-md">
                <div className="flex w-full gap-5 justify-start items-center">
                
                  <h1 className="text-sm bg-amber-500 bg-opacity-85 px-2 py-1 rounded-full mb-3">
                  User-0.0 k
                  </h1>
                </div>
                

<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left rtl:text-right whitespace-nowrap text-gray-500 dark:text-gray-400">
        <thead class="text-md text-gray-200 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-0">
                Rank
                </th>
                <th scope="col" class="px-6 py-0">
                  Username
                </th>
                <th scope="col" class="px-6 py-0">
                Gems Earned
                </th>
                <th scope="col" class="px-6 py-0">
                Users Referred
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class=" border-b dark:bg-gray-800 dark:border-gray-900">
            <td class="px-6 py-4">
                    1
                </td>
                <td class="px-6 py-4 text-cyan-500">
                MOC0098
                </td>
                <td class="px-6 py-4 text-yellow-400">
                $343
                </td>
                <td class="px-6 py-4">
                @eewrett
                </td>
            </tr>
            <tr class=" border-b dark:bg-gray-800 dark:border-gray-900">
            <td class="px-6 py-4">
                    2
                </td>
                <td class="px-6 py-4 text-cyan-500">
                MOC0098
                </td>
                <td class="px-6 py-4 text-yellow-400">
                $343
                </td>
                <td class="px-6 py-4">
                @eewrett
                </td>
            </tr>

        </tbody>
    </table>
</div>

              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
