export const API_URLS = {
  ProjectList: "ProjectList",
  TaskDatabyProjectid: "TaskDatabyProjectid",
  getProjectcpuList: "getProjectcpuList",
  createnewuser: "createnewuser",
  getDashboard: "getDashboard",
  login: "login",
  UserCreateTask: "UserCreateTask",
  xlogin: "login",
  loginUser: "loginUser",
  ClientList: "ClientList",
  projectlistbyclient: "projectlistbyclient",
  taskgrouplistbyproject: "taskgrouplistbyproject",
  tasklistbytaskgroup: "tasklistbytaskgroup",
  taskdetailbytask: "taskdetailbytask",
  UserSocialAction: "UserSocialAction",
  UpdateProfile: "UpdateProfile",
  UserQuestAction: "UserQuestAction",
  Rewardlist: "Rewardlist",
  UpdateProfileLogo: "UpdateProfileLogo",
  ChangePassword: "ChangePassword",
  projectlistbyClientwithCrypto: "projectlistbyClientwithCrypto",
  gettasklistbyclientprojectcate: "gettasklistbyclientprojectcate",
  UserWeb3Task: "UserWeb3Task",
  TelegramLogin: "TelegramLogin",
  clientfollowingbyuser: "clientfollowingbyuser",
  projectfollowingbyuser: "projectfollowingbyuser",
  ratingforproject: "ratingforproject",
  TopdealClientList: "TopdealClientList",
  getNonce: "getNonce",
  getTaskhistory: "getTaskhistory",
  tasklistbytask: "tasklistbytask",
  getReferList: "getReferList",
  tasklistbyproject: "tasklistbyproject",
  Articlelist: "Articlelist",
  getTaskgroupbyStatus: "getTaskgroupbyStatus",
};
