import React, { useContext } from "react";
import { StakingApp } from "../UserDasbord/StateMengmanet";

export default function Footer() {
  const { theme } = useContext(StakingApp);
  return (
    <footer
      id="footer"
      className={`bg-${theme === "dark" ? "#000000" : theme} position-relative`}
    >
      <div class="footer-top position-relative">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 footer-contact">
              <div class="footer_logo">
                <img src="assets/img/moc_icon.png" alt="" />
              </div>
              <p>
                We provide advisory services to the companies working in the
                Crypto, Web3 and Metaverse space, with the help of our vast
                experience and expertise in the field.
              </p>
            </div>

            <div class="col-lg-2 offset-lg-1 col-md-6 col-6 footer-links">
              <h4>Company</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i> <a>Home</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a>About us</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a>Social</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a>Terms of service</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a>Privacy policy</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-2 offset-lg-1 col-md-6 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <a class="border border-info px-3 py-2">Airdrop</a>
                <li>
                  <i class="bx bx-chevron-right"></i> <a>Top Deals</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a>Articles</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a>News</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a>Videos</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Our Social Networks</h4>

              <div class="social-links mt-3">
                <a
                  href="https://twitter.com/Mysteryofcrypt"
                  target="_blank"
                  class="twitter"
                >
                  <i class="bx bx_x">
                    <img src="assets/img/x.png" alt="" />
                  </i>
                </a>
                <a
                  href="https://t.me/Mysteryofcrypto"
                  target="_blank"
                  class="linkedin"
                >
                  <i class="bx bxl-telegram"></i>
                </a>
                <a
                  href="https://www.youtube.com/c/mysteryofcrypto"
                  target="_blank"
                  class="Youtube"
                >
                  <i class="bx bxl-youtube"></i>
                </a>
                <a
                  href="https://www.instagram.com/mysteryofcrypto/"
                  target="_blank"
                  class="instagram"
                >
                  <i class="bx bxl-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container footer-bottom clearfix">
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>
              Mystery of Crypto - <span class="text-theme-color">MOC</span>
            </span>
          </strong>
        </div>
        <div class="credits"></div>
      </div>
    </footer>
  );
}
