import React, { useContext, useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import SideDrawer from "../../Pages/SideDrawer";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
const Connect = () => {
  const { theme } = useContext(StakingApp);
  const { open } = useWeb3Modal();
  const { address, isConnected, isDisconnected } = useAccount();

  return (
    <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
      <main class="dkk09 regbox" id="main">
        <section class="st04 about">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="bgsubscribe">
                  <div class="row align-items-center">
                    <div class="col-lg-5">
                      <h3 className="my-2 fs-5 font-bold my-3">
                        Connect a Wallet
                      </h3>
                      <a>
                        <div
                          onClick={() => open()}
                          className=" metamsk flex justify-center items-center bg-white py-3 gap-5 rounded-2xl cursor-pointer"
                        >
                          <span>
                            <img
                              src="assets/img/metamask.png"
                              alt=""
                              className="h-8"
                            />
                          </span>{" "}
                          <p className="text-black font-bold">Metamask</p>
                        </div>
                      </a>
                      <div class="or09">
                        <span class="org">OR</span>
                      </div>
                      <div class="swg flex justify-center items-center bg-white py-3 gap-5 rounded-2xl cursor-pointer">
                        <a href="/signup" className="flex">
                          <span>
                            <img src="assets/img/googleicon.png" alt="signup" />
                          </span>
                          <p className="text-black font-bold">
                            Signup With Google
                          </p>
                        </a>
                      </div>
                    </div>
                    <div class="col-lg-6 offset-lg-1">
                      <h4 class="text-theme-color">What is Wallet?</h4>
                      <div className={`bg-${theme} p-3 rounded`}>
                        <p class="font-weight-bold">
                          A Home for your Digital Assets
                        </p>
                        <p class="small">
                          Wallets are used to send, receive, store, and display
                          digital assets like Ethereum and NFTs.
                        </p>
                        <p class="font-weight-bold">A New Way to Log In</p>
                        <p class="small">
                          Instead of creating new accounts and passwords on
                          every website, just connect your wallet.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Connect;
