import React, { useEffect, useState } from "react";
import "./vtabs.css";
import axiosInstance1 from "../../Config/axiosuser";
import { API_URLS } from "../../Config/apiUrls";
import { toast } from "react-toastify";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import twite from "../../Img/twitter.png";
import youtube from "../../Img/youtube.png";

import dicord from "../../Img/dicord.png";
import telegram from "../../Img/telegram.png";
import PersonIcon from "@mui/icons-material/Person";
import TelegramLogin1 from "../../Admindashborde/Telegram copy";
import { useNavigate } from "react-router-dom";
const Vtabs = () => {
  const [activeTab, setActiveTab] = useState("tab2");
  const [firstname, setFirstName] = useState("");
  const token = localStorage.getItem("token");
  const [x, setX] = useState("");
  const [you, setYou] = useState("");
  const [dis, setDis] = useState("");
  const [teli, setTeli] = useState("");
  const [copy, setcopy] = useState();
  const [data1, setData1] = useState();
  const [getrefral, setGedtrefaral] = useState();
  const navegate = useNavigate();
  const tab1 = localStorage.getItem("tab");
  const [tab, setTab] = useState(2);
  const path = localStorage.getItem("taskPath");

  useEffect(() => {
    tab1 === "1" && setTab(1);
  }, []);

  useEffect(() => {
    setX(localStorage.getItem("x"));
    setYou(localStorage.getItem("you"));
    setDis(localStorage.getItem("dis"));
    setTeli(localStorage.getItem("teli"));
  });

  const TaskDeatials = () => {
    axiosInstance1
      .get(API_URLS.getTaskhistory)
      .then((res) => {
        setData1(res.data.Taskshistory);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(data1, "data1");

  useEffect(() => {
    TaskDeatials();
  }, []);

  const onCopyLink = (id) => {
    navigator?.clipboard?.writeText(id);
    toast.success(`Copy ${id}`);
    setcopy(id);
  };

  const AddProject = () => {
    axiosInstance1
      .get(API_URLS.getDashboard)
      .then((res) => {
        setFirstName(res.data.userdata);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMyRefeal = () => {
    axiosInstance1
      .get(API_URLS.getReferList)
      .then((res) => {
        setGedtrefaral(res.data.ReferList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    AddProject();
    getMyRefeal();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    !token && navegate("/login");
  }, [token]);

  return (
    <main id="main">
      <section className="prflbg about innnerpage md:mt-5 mt-1">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="flex justify-between ">
                <h1 className="my-4">My profile</h1>
                {path && (
                  <span className="flex border w-20 h-10 justify-center items-center rounded-xl cursor-pointer hover:bg-[#14B8A6]">
                    <p
                      onClick={() => navegate(`/${JSON.parse(path)}`)}
                      className="text-center"
                    >
                      Back
                    </p>
                  </span>
                )}
              </div>

              <div className="content">
                {/* <input type="radio" name="slider" id="home" />
                <input type="radio" name="slider" id="blog" />
                <input type="radio" name="slider" id="help" />
                <input type="radio" name="slider" id="refferal" /> */}
                <div className="list gap-3 mt-3">
                  <label
                    onClick={() => setTab(1)}
                    className={`${
                      tab === 1 ? "bg-[#348F8F]" : "bg-[#172D32]"
                    } py-3 px-2 text-sm rounded-lg cursor-pointer`}
                  >
                    <span>Profile</span>
                  </label>
                  <label
                    onClick={() => setTab(2)}
                    className={`${
                      tab === 2 ? "bg-[#348F8F]" : "bg-[#172D32]"
                    } py-3 px-2 text-sm rounded-lg cursor-pointer`}
                  >
                    <span>
                      <span className="hidden md:inline-block">Linked</span>{" "}
                      Accounts
                    </span>
                  </label>
                  <label
                    onClick={() => setTab(3)}
                    className={`${
                      tab === 3 ? "bg-[#348F8F]" : "bg-[#172D32]"
                    } py-3 px-2 text-sm rounded-lg cursor-pointer`}
                  >
                    <span>Earnings</span>
                  </label>
                  <label
                    onClick={() => setTab(4)}
                    className={`${
                      tab === 4 ? "bg-[#348F8F]" : "bg-[#172D32]"
                    } py-3 px-2 text-sm rounded-lg cursor-pointer`}
                  >
                    <span>My Refferal</span>
                  </label>
                </div>

                <div className="text-content proswitch md:p-10 p-2  rounded-md">
                  {tab === 1 && (
                    <div className="home text">
                      <div className="flex flex-wrap">
                        <div>
                          {firstname?.imagePath ? (
                            <img
                              src={firstname?.imagePath}
                              style={{ height: "100px", width: "100px" }}
                              alt="User"
                            />
                          ) : (
                            <div className="flex justify-center items-center rounded-full h-10 w-16 ">
                              <PersonIcon className="!text-[#fff]" />
                            </div>
                          )}
                        </div>
                        <div className="md:ml-4">
                          <h1 className="title">My Account</h1>
                          <div className="flex flex-wrap my-3">
                            <div className="text-sm">User Id:</div>
                            <div className="flex-grow md:ml-3 text-sm">
                              {firstname?.user_id}
                            </div>
                          </div>
                          <div className="flex flex-wrap my-3">
                            <div className="text-sm">Earn Point :</div>
                            <div className=" text-sm flex-grow md:ml-3">
                              {firstname?.totalpoint}
                            </div>
                          </div>
                          <div className="flex flex-wrap my-3">
                            <div className=" text-sm">Referral Link :</div>
                            <div className="flex-grow md:ml-3">
                              <p className=" text-sm ">
                                {` https://website.aquaeal.in/?Referral=${firstname?.yourReferralcode}`}
                                {copy ===
                                ` https://website.aquaeal.in/?Referral=${firstname?.yourReferralcode}` ? (
                                  <FileCopyIcon className="ml-2" />
                                ) : (
                                  <ContentCopyIcon
                                    className="ml-2"
                                    onClick={() =>
                                      onCopyLink(
                                        ` https://website.aquaeal.in/?Referral=${firstname?.yourReferralcode}`
                                      )
                                    }
                                  />
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-wrap my-3">
                            <div className=" text-sm">Referral Code :</div>
                            <div className=" flex-grow md:ml-3">
                              <p className=" text-sm ">
                                {firstname?.yourReferralcode}

                                {copy === firstname?.yourReferralcode ? (
                                  <FileCopyIcon className="text-sm ml-2" />
                                ) : (
                                  <ContentCopyIcon
                                    className="text-sm ml-3"
                                    onClick={() =>
                                      onCopyLink(firstname?.yourReferralcode)
                                    }
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-wrap my-3">
                            <div className=" text-sm">Sponsor :</div>
                            <div className=" flex-grow md:ml-3">
                              <p className=" text-sm ">
                                {firstname?.isReferral
                                  ? firstname?.isReferral
                                  : "--"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {tab === 2 && (
                    <div className="blog text">
                      <div className="prosocial my-4">
                        <div className="flex justify-between items-center w-full px-3">
                          <div>
                            <span className="ssicon">
                              <img src={twite} alt="MoC" />
                            </span>

                            <input
                              type="text"
                              className="hidden md:block md:pl-18 pl-12 text-sm form-control !border-none !outline-none "
                              value={"X"}
                            />
                          </div>
                          {x === "0" ? (
                            <a
                              href={`https://restapi.aquaeal.in/TwitterAuth/login?UserToken=${token}`}
                              className="bg-blue-500 py-1 px-3 rounded-lg font-semibold text-sm"
                            >
                              Link with X
                            </a>
                          ) : (
                            <CheckCircleIcon className="!text-green-500" />
                          )}
                        </div>
                      </div>
                      <div className="prosocial my-4">
                        <div className="flex justify-between items-center w-full px-3">
                          <div>
                            <span className="ssicon">
                              <img src={youtube} alt="MoC" />
                            </span>
                            <input
                              type="text"
                              className="hidden md:block md:pl-18 pl-12 text-sm form-control !border-none !outline-none"
                              value={"You Tube"}
                            />
                          </div>
                          {you === "0" ? (
                            <a
                              href={`https://restapi.aquaeal.in/SocialLoginAuth/Youtube?UserToken=${token}`}
                              className="bg-red-500 py-1 px-3 rounded-lg font-semibold text-sm"
                            >
                              Link with you tube
                            </a>
                          ) : (
                            <CheckCircleIcon className="!text-green-500" />
                          )}
                        </div>
                      </div>
                      <div className="prosocial my-4">
                        <div className="flex justify-between items-center w-full px-3">
                          <div>
                            <span className="ssicon">
                              <img src={dicord} alt="MoC" />
                            </span>
                            <input
                              type="text"
                              className="hidden md:block md:pl-18 pl-12 w[80%] text-sm form-control !border-none !outline-none"
                              value={"Discord"}
                            />
                          </div>
                          {dis === "0" ? (
                            <a
                              href={`https://restapi.aquaeal.in/SocialLoginAuth/Discord?UserToken=${token}`}
                              className="bg-blue-500 py-1 md:px-3 px-1 rounded-lg font-semibold text-sm"
                            >
                              Link with Discord
                            </a>
                          ) : (
                            <CheckCircleIcon className="!text-green-500" />
                          )}
                        </div>
                      </div>
                      <div className="ifrm09 prosocial my-4">
                        <div className="flex justify-between items-center w-full px-3">
                          <div>
                            <span className="ssicon">
                              <img src={telegram} alt="MoC" />
                            </span>
                            <input
                              type="text"
                              className="hidden md:block md:pl-18 pl-12 text-sm form-control !border-none !outline-none"
                              value={"Telegram"}
                            />
                          </div>

                          {teli === "0" ? (
                            <div className="nframe e">
                              <TelegramLogin1 />
                            </div>
                          ) : (
                            <CheckCircleIcon className="!text-green-500" />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {tab === 3 && (
                    <div className="help text">
                      <div className="my-6 overflow-auto h-[60vh]">
                        {data1?.map((i) => {
                          return (
                            <div className="incomeBox flex flex-wrap items-center justify-between text-start">
                              <div className="smicon flex-none w-10">
                                {" "}
                                <img
                                  src={
                                    i.subcategory === "Twitter-X"
                                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/tw.jpg"
                                      : i.subcategory === "Youtube"
                                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/youtube.jpg"
                                      : i.subcategory === "Discord"
                                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/discord.jpg"
                                      : i.subcategory === "Telegram"
                                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/teligram.jpg"
                                      : i.subcategory === "Web3"
                                      ? "https://restapi.aquaeal.in/public/upload/earnfrom/crypto.jpg"
                                      : ""
                                  }
                                  className="rounded my-1 "
                                  alt="MoC"
                                />
                              </div>
                              <div className="flex-initial w-96">
                                <h4 className=" align-self-start text-sm ovrfl01 font-bold">
                                  {" "}
                                  {i.taskTitle}
                                </h4>
                              </div>
                              <div>
                                <span className="small2">Earned Points</span>
                                <h4 className=" text-sm font-bold">
                                  {i.earnpoint}
                                </h4>
                              </div>
                              <div>
                                <span className="small2">Earned Date</span>
                                <h4 className=" text-sm font-bold">
                                  {i.updated_at}
                                </h4>
                              </div>
                              <div>
                                <span className="small2">Status</span>
                                <h4 className=" text-sm font-bold">
                                  {i.status === "0" ? (
                                    <p className=" text-center small2 bg-orange-200 px-2 rounded-lg">
                                      Pending
                                    </p>
                                  ) : (
                                    <h4 className=" text-center small2 bg-lime-700 px-2 rounded-lg">
                                      Complete
                                    </h4>
                                  )}
                                </h4>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {tab === 4 && (
                    <div className="refferal text">
                      <div className="my-6">
                        <div className=" mb-2 flex flex-wrap items-center justify-between text-start px-2">
                          <div className="smicon flex-none w-10">
                            <h4 className=" align-self-start text-sm font-bold">
                              Address
                            </h4>
                          </div>
                          <div className="flex-initial w-96">
                            <h4 className=" align-self-start text-sm ovrfl01 font-bold">
                              User ID
                            </h4>
                          </div>
                          <div>
                            {/* <span className="small2">Earned Points</span> */}
                            <h4 className=" text-sm font-bold">Email</h4>
                          </div>
                          <div>
                            <h4 className=" text-sm font-bold">Referral</h4>
                          </div>
                        </div>
                        {getrefral?.map((i) => {
                          return (
                            <div className="incomeBox flex flex-wrap items-center justify-between text-start">
                              <div className="smicon flex-none w-10">
                                <h4 className=" align-self-start text-sm  font-bold">
                                  {i.address
                                    ? i.address?.slice(0, 5)
                                    : "NullAddress"}
                                  {i.address ? "..." : ""}
                                  {i.address?.slice(38)}
                                </h4>
                              </div>
                              <div className="flex-initial w-96">
                                <h4 className=" align-self-start text-sm  font-bold">
                                  {i.user_id ? i.user_id?.slice(0, 5) : "--"}
                                  {i.user_id ? "..." : ""}
                                  {i.user_id?.slice(32)}
                                </h4>
                              </div>
                              <div>
                                {/* <span className="small2">Earned Points</span> */}
                                <h4 className=" text-sm font-bold">
                                  {i.email
                                    ? i.email?.slice(0, 5)
                                    : "Null Email"}
                                  {i.email ? "..." : ""}
                                  {i.email?.split("@")?.[1]}
                                </h4>
                              </div>
                              <div>
                                <h4 className=" text-sm font-bold">
                                  {i.isReferral ? i.isReferral : "--"}
                                </h4>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Vtabs;
