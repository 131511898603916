import Articles from "../../Components/Admindashborde/Articles";
import Coindetail from "../../Components/Admindashborde/Coindetail/Coindetail";
import Forgotpassword from "../../Components/Admindashborde/Forgotpassword";
import ProjectTask from "../../Components/Admindashborde/ProjectTask";
import TelegramWidget from "../../Components/Admindashborde/Telegram";
import TelegramLogin from "../../Components/Admindashborde/Telegram";
import TelegramLogin1 from "../../Components/Admindashborde/Telegram copy";
import Webtask from "../../Components/Admindashborde/Webtask";
import Connect from "../../Components/Admindashborde/connect";
import AllTask from "../../Components/AllTask";
import Clinte from "../../Components/Pages/Clinte";
import CryptoSpace from "../../Components/Pages/CryptoSpace";
import ExploreTask from "../../Components/Pages/ExploreTask";
import GrviewList from "../../Components/Pages/GrviewList";
import Home from "../../Components/Pages/Home";
import Leaderboard from "../../Components/Pages/Leaderboard";
import Login from "../../Components/Pages/Login";
import MyProfile from "../../Components/Pages/MyProfile";
import Vtabs from "../../Components/Pages/Profile";
import Signup from "../../Components/Pages/Signup";
import TaskDtl from "../../Components/Pages/TaskDtl";
import Userpro from "../../Components/Pages/Userpro";
import Text from "../../Components/Text";
import UserDasbord from "../../Components/UserDasbord";

const routes = [
  {
    id: 1,
    path: "/telegramLogin",
    element: <TelegramLogin />,
  },
  {
    id: 1,
    path: "/TelegramLogin1",
    element: <TelegramLogin1 />,
  },
  {
    id: 1,
    path: "/login",
    element: <Login />,
  },
  {
    id: 1,
    path: "/signup",
    element: <Signup />,
  },
  {
    id: 1,
    path: "/forgot-password",
    element: <Forgotpassword />,
  },
  {
    id: 1,
    path: "/connect",
    element: <Connect />,
  },
  {
    id: 1,
    path: "/coindetail",
    element: <Coindetail />,
  },
  {
    id: 1,
    path: "/userpro",
    element: <Userpro />,
  },

  {
    id: 1,
    path: "/profile",
    element: <Vtabs />,
  },
  {
    id: 1,
    path: "/clinte",
    element: <Clinte />,
  },

  {
    id: 1,
    path: "/TaskDtl",
    element: <TaskDtl />,
  },
  {
    id: 1,
    path: "/task",
    element: <UserDasbord />,
  },
  {
    id: 1,
    path: "/GrviewList",
    element: <GrviewList />,
  },
  {
    id: 1,
    path: "/CryptoSpace",
    element: <CryptoSpace />,
  },
  {
    id: 1,
    path: "/home",
    element: <ExploreTask />,
  },
  {
    id: 1,
    path: "/webtask",
    element: <Webtask />,
  },
  {
    id: 1,
    path: "/Text",
    element: <Text />,
  },
  {
    id: 1,
    path: "/all-task",
    element: <AllTask />,
  },
  {
    id: 1,
    path: "/project-task",
    element: <ProjectTask />,
  },
  {
    id: 1,
    path: "/leader-board",
    element: <Leaderboard />,
  },
  {
    id: 1,
    path: "/articles",
    element: <Articles />,
  },
];
export default routes;
