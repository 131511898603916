import React, { useContext, useEffect, useState } from "react";
import SideDrawer from "../Pages/SideDrawer";
import axiosInstance from "../Config/axios";
import { API_URLS } from "../Config/apiUrls";
import { useNavigate } from "react-router-dom";
import { StakingApp } from "./StateMengmanet";
import axiosInstancea1 from "../Config/axiosuser copy";

const UserDasbord = () => {
  const [data, setData] = useState();
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const navigate = useNavigate();
  const { setId, theme } = useContext(StakingApp);
  const token = localStorage.getItem("token");
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  useEffect(() => {
    localStorage.setItem("code", code);
  }, [code]);

  const loginUser = () => {
    axiosInstancea1
      .get(API_URLS.loginUser + `/${code}`, {})
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loginUser();
  }, [code]);

  useEffect(() => {
    !token && navigate("/login");
  }, []);

  const AddProject = () => {
    axiosInstance
      .get(API_URLS.getProjectcpuList)
      .then((res) => {
        setData(res.data.projectList.upcoming);
        setData1(res.data.projectList.current);
        setData2(res.data.projectList.past);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    AddProject();
  }, []);

  return (
    <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
      <main id="main">
        <section class="innnerpage mt-5">
          <div class="container">
            <div class="section-title">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <a>
                    <h4>Task </h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row my-4 justify-content-center">
              <div class="col-lg-10">
                <div class="emf09">
                  <div>
                    <div class="game-progress-bar-container game-count-5">
                      <div class="progress-steps-section">
                        <ul class="progress-steps">
                          <li class="game-completed">
                            <div class="progress-step-item"></div>
                            <span class="small">Starter</span>
                          </li>
                          <li>
                            <div class="progress-step-item"></div>
                            <span class="small">Achiever</span>
                          </li>
                          <li>
                            <div class="progress-step-item"></div>
                            <span class="small">Master</span>
                          </li>
                          <li class="game-active">
                            <div class="progress-step-item"></div>
                            <span class="small">Winner</span>
                          </li>
                        </ul>
                        <div class="progress-steps-connector game-2">
                          <div class="progress-indicator"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="about position-relative">
          <div class="ellipse-glow-item ellipse-1"></div>
          <div class="container">
            <div class="section-title d-flex justify-content-between align-items-center">
              <div>
                <h2>Current</h2>
              </div>
              <div>
                {/* <a href="#">
                  View All <i class="bx bx-arrow-from-left"></i>
                </a> */}
              </div>
            </div>
            <div class="row">
              {data1?.map((it) => {
                return (
                  <div class="col-lg-4 col-md-6">
                    <div class="tdradius td001 td_bg1">
                      <span className="flex justify-end ">
                        <p className=" rounded-lg  px-3 text-sm text-black font-bold bg-opacity-40">
                          {(new Date(it.endtime).getTime() -
                            new Date(it.starttime).getTime()) /
                            (1000 * 3600 * 24)}{" "}
                          days
                        </p>
                      </span>

                      <div class="coinbox">
                        <div class="cncover">
                          <img src={it.image_path} alt="MoC" />
                        </div>
                        <div class="cname">{it.name}</div>
                      </div>
                      <div class="tdtext">
                        <p>{it.project_title}</p>
                        <p className="truncate">{it.project_description}</p>
                      </div>
                      <div class="tdbtn_cover">
                        <div class="btn1">
                          <a
                            onClick={() =>
                              navigate("/coindetail", {
                                state: { id: it.id },
                              })
                            }
                          >
                            <button>Task List</button>
                          </a>
                        </div>
                        {/* <div class="btn2">
                          <a href={`${it.signup_link}`} target="_blank">
                            <button
                            // onClick={() => navigate(`${it.signup_link}`)}
                            >
                              Sign Up
                            </button>
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section class="about position-relative">
          <div class="ellipse-glow-item ellipse-1"></div>
          <div class="container">
            <div class="section-title d-flex justify-content-between align-items-center">
              <div>
                <h2>Upcoming</h2>
              </div>
              <div>
                {/* <a href="#">
                  View All <i class="bx bx-arrow-from-left"></i>
                </a> */}
              </div>
            </div>
            <div class="row">
              {data?.map((it) => {
                return (
                  <div class="col-lg-4 col-md-6">
                    <div class="tdradius td001 td_bg1">
                      <span className="flex justify-end ">
                        {/* <p className=" rounded-lg  px-3 text-sm text-black font-bold bg-opacity-40">
                          {(new Date(it.starttime).getTime() - date.getTime()) /
                            (1000 * 3600 * 24)}
                          days Upcoming
                        </p> */}
                      </span>
                      <div class="coinbox">
                        <div class="cncover">
                          <img src={it.image_path} alt="MoC" />
                        </div>
                        <div class="cname">{it.name}</div>
                      </div>
                      <div class="tdtext">
                        <p>{it.project_title}</p>
                        <p className="truncate">{it.project_description}</p>
                      </div>
                      <div class="tdbtn_cover">
                        <div class="btn1">
                          <a
                            onClick={() =>
                              navigate("/coindetail", {
                                state: { id: it.id },
                              })
                            }
                          >
                            <button>Task List</button>
                          </a>
                        </div>
                        {/* <div class="btn2">
                          <a href={`${it.signup_link}`} target="_blank">
                            <button
                            // onClick={() => navigate(`${it.signup_link}`)}
                            >
                              Sign Up
                            </button>
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section class="about position-relative">
          <div class="ellipse-glow-item ellipse-1"></div>
          <div class="container">
            <div class="section-title d-flex justify-content-between align-items-center">
              <div>
                <h2>Past</h2>
              </div>
              <div>
                {/* <a href="#">
                  View All <i class="bx bx-arrow-from-left"></i>
                </a> */}
              </div>
            </div>
            <div class="row">
              {data2?.map((it) => {
                return (
                  <div class="col-lg-4 col-md-6">
                    <div class="tdradius td001 td_bg1">
                      <div class="coinbox">
                        <div class="cncover">
                          <img src={it.image_path} alt="MoC" />
                        </div>
                        <div class="cname">{it.name}</div>
                      </div>
                      <div class="tdtext">
                        <p>{it.project_title}</p>
                        <p className="truncate">{it.project_description}</p>
                      </div>
                      <div class="tdbtn_cover">
                        <div class="btn1">
                          <a
                            onClick={() =>
                              navigate("/coindetail", {
                                state: { id: it.id },
                              })
                            }
                          >
                            <button>Task List</button>
                          </a>
                        </div>
                        {/* <div class="btn2">
                          <a href={`${it.signup_link}`} target="_blank">
                            <button
                            // onClick={() => navigate(`${it.signup_link}`)}
                            >
                              Sign Up
                            </button>
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default UserDasbord;
