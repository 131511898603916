import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import { useNavigate } from "react-router-dom";
import { StakingApp } from "../../UserDasbord/StateMengmanet";

const CryptoSpace = () => {
  const [data, setData] = useState();
  const { theme } = useContext(StakingApp);
  const navgate = useNavigate();

  const AddProject = () => {
    axiosInstance
      .get(API_URLS.projectlistbyClientwithCrypto)
      .then((res) => {
        setData(res.data.projectData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    AddProject();
  }, []);

  const gotaskPage = (projrct, category, clientId) => {
    localStorage.setItem("projrct", projrct);
    localStorage.setItem("category", category);
    localStorage.setItem("clientId", clientId);

    navgate("/webtask");
  };
  return (
    <section
      className={`bg-${theme === "dark" ? "#000000" : theme} innnerpage prflbg`}
    >
      <div className={`bg-${theme === "dark" ? "cspc09" : theme} `}>
        <div className="container my-2">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-8 text-center">
              <h1>MoC Crypto Space</h1>
              <h4>
                We are creating whole new scope of Web 3.0 opportunities in one
                place.
              </h4>
              <p className="mb-3">
                MoC offers the excellent way to explore participate and gain
                rewards through easy participation of communities projects.
              </p>
              <h4 className="text-theme-color font-weight-bold">
                Let's Start our Web 3.0 Journey
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-3">
        <div className="row justify-center">
          <div className="col-lg-3 col-md-6">
            <div className="expbox2 text-center">
              <p>Total Community Projects Onboard</p>
              <h2 className="mt-2 text-white">
                <span className="pb09">
                  <img src="assets/img/cr6.png" alt="MoC" />
                  <img src="assets/img/cr4.png" alt="MoC" />
                  <img src="assets/img/cr5.png" alt="MoC" />
                </span>
                4.8K+
              </h2>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="expbox2 text-center">
              <p>Total Distribution of Community</p>
              <h2 className="mt-2 text-white">
                <span className="pb09 social-links">
                  <i class="text-info bx bxl-twitter"></i>
                  <i class="text-blue bx bxl-telegram"></i>
                  <i class="text-primary bx bxl-facebook"></i>
                  <i class="text-danger bx bxl-youtube"></i>
                </span>
                $200M
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="row">
              <div className="col-lg-12">
                <div class="tabs">
                  <input
                    type="radio"
                    name="tabs"
                    id="tabone"
                    checked="checked"
                  />
                  <label for="tabone">All</label>
                  <div class="tab">
                    <div className="row mb-4">
                      <div className="col-lg-8 col-md-8">
                        <div className="fl09">
                          <div className="search02">
                            <span className="bx bx-search-alt-2"></span>
                            <input placeholder="Search" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="fl09 text-center cursor-pointer fnt01">
                        <i className="bx bx-filter"></i> Show Trending
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="fl09 text-center cursor-pointer fnt01">
                          <i className="bx  bx-check-circle"></i> Available to
                          claim
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3 mb-4">
                      <div className="d-flex flex-wrap">
                        <span className="fltbtn09">Points</span>
                        <span className="fltbtn09">Token</span>
                        <span className="fltbtn09">New Work</span>
                        <span className="fltbtn09">MoC</span>
                        {/* <span className="bg-info fltbtn09 ml-auto">
                          Shortered
                        </span> */}
                      </div>
                    </div>
                    <div className="row">
                      {data?.map((i) => {
                        return (
                          <div className="col-md-6">
                            <div
                              onClick={() =>
                                gotaskPage(i.id, i.category_id, i.clientId)
                              }
                              className={`bg-${
                                theme === "dark" ? "#000000" : theme
                              } expbox2 mb-2 cursor-pointer `}
                            >
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="dtlcover">
                                    <div className="text-center pr06">
                                      <img src={i.clientLogo} alt="MoC" />
                                      <h4>{i.clientTitle}</h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-9">
                                  <div className="spc098 text-center d-flex justify-content-between flex-wrap">
                                    <div className="text-center">
                                      <span className="small text-muted">
                                        Funding
                                      </span>
                                      <h4>$8.5M</h4>
                                    </div>
                                    <div>
                                      <span className="small">Reward</span>
                                      <div className="flex gap-2">
                                        {i.RewardName.map((i) => {
                                          return (
                                            <h4
                                              style={{ fontSize: "10px" }}
                                              className="pl01"
                                            >
                                              {i.name}
                                            </h4>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div>
                                      <span className="small">Status</span>
                                      <div className="text-center stts">
                                        <img
                                          src="assets/img/check.png"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div></div>
                                  </div>
                                  <div className="my-3 spdcpt">
                                    {i.projectDescription}
                                  </div>
                                  <div className="d-flex justify-between flex-wrap">
                                    <div className="ntlinks">
                                      <h5 className="text-muted small">
                                        Network:{" "}
                                        <span className="font-weight-bold">
                                          {i.clienttokenNetwork}
                                        </span>
                                      </h5>
                                    </div>
                                    <div className="ntlinks">
                                      <h5 className="text-muted small">
                                        Social Links:{" "}
                                        <span>
                                          <a href={i.clientsocaillink}>
                                            <i className="bx bxl-dribbble"></i>
                                          </a>{" "}
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {/* 
                    <div className="expbox2 mb-2">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="dtlcover">
                            <div className="text-center pr06">
                              <img src="assets/img/square.jpg" alt="MoC" />
                              <h4>Morph</h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="spc098 text-center d-flex justify-content-between flex-wrap">
                            <div className="text-center">
                              <span className="small text-muted">Funding</span>
                              <h4>$8.5M</h4>
                            </div>
                            <div>
                              <span className="small">Category</span>
                              <h4>$8.5M</h4>
                            </div>
                            <div>
                              <span className="small">Status</span>
                              <div className="text-center stts">
                                <img src="assets/img/check.png" alt="" />
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="my-3 spdcpt">
                            Solana is known for its high throughput, capable of
                            processing thousands of transactions per second
                            (TPS). This is achieved through its unique consensus
                            mechanism called Proof of History (PoH), which
                            timestamps transactions before they are added to the
                            blockchain.{" "}
                          </div>
                          <div className="d-flex justify-between flex-wrap">
                            <div className="ntlinks">
                              <h5 className="text-muted small">
                                Network:{" "}
                                <span className="font-weight-bold">
                                  Solana Chain
                                </span>
                              </h5>
                            </div>
                            <div className="ntlinks">
                              <h5 className="text-muted small">
                                Social Links:{" "}
                                <span>
                                  <a href="#">
                                    <i className="bx bxl-twitter"></i>
                                  </a>{" "}
                                  <a href="#">
                                    <i className="bx bxl-dribbble"></i>
                                  </a>{" "}
                                  <a href="#">
                                    <i className="bx bxl-telegram"></i>
                                  </a>{" "}
                                  <a href="#">
                                    <i className="bx bxl-medium-old"></i>
                                  </a>{" "}
                                  <a href="#">
                                    <i className="bx"></i>
                                  </a>
                                </span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CryptoSpace;
