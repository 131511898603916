import React, { useContext, useEffect, useState } from "react";
import { StakingApp } from "../../UserDasbord/StateMengmanet";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";

export default function Articles() {
  const { theme } = useContext(StakingApp);
  const id = localStorage.getItem("articlesId");

  const [article, setArticle] = useState();

  const getArticle = () => {
    axiosInstance
      .get(API_URLS.Articlelist + `/${id}`)
      .then((res) => {
        setArticle(res.data.articleList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getArticle();
  }, []);

  console.log(article, "article");

  return (
    <div className={`bg-${theme === "dark" ? "#000000" : theme}`}>
      <main id="main">
        <section class="prflbg about innnerpage">
          <div className="flex flex-col px-10 ">
            <img
              src={article?.[0]?.imagePath}
              alt=""
              style={{ height: "500px" }}
            />
            <h2 class="mt-md-4 text-3xl font-bold">{article?.[0]?.title}</h2>
            <h1 class="mt-md-4 ">{article?.[0]?.subtitle}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: article?.[0]?.describtion,
              }}
            ></div>
          </div>
        </section>
      </main>
    </div>
  );
}
