import React from "react";
import "./loder.css";
export default function Loder() {
  return (
    <div>
      <div class="preloder">
        <div class="loder"></div>
      </div>
    </div>
  );
}
