import React, { useEffect } from "react";
import $ from "jquery";
import "./viewlist.css";
import { useNavigate } from "react-router-dom";
const GrviewList = () => {
  const navigate = useNavigate();
  useEffect(() => {
    $(document).ready(function () {
      $("#list").click(function (event) {
        event.preventDefault();
        $("#products .item").addClass("list-group-item");
      });

      $("#grid").click(function (event) {
        event.preventDefault();
        $("#products .item").removeClass("list-group-item");
        $("#products .item").addClass("grid-group-item");
      });
    });
  }, []);

  return (
    <div>
      <div className="view-options">
        <button className="btn btn-secondary btn-sm mr-2" id="list">
          <i className="bx bx-list-ul"></i> List View
        </button>
        <button className="btn btn-primary btn-sm" id="grid">
          <i className="bx bx-grid-small"></i> Grid View
        </button>
      </div>
      <div id="products">
        <div className=" mt-4 row item">
          <div className="item col-xs-4 col-lg-4">
            <a href="./TaskDtl">
              <div className="expbox">
                <div className="d-flex justify-content-start">
                  <span className="pl01">
                    <span className="tskcoin">
                      <img src="assets/img/usdt.png" alt="MoC" />
                    </span>
                    500 USDT
                  </span>
                  <span className="pl01">30 Points</span>
                  <span className="ml-auto">
                    <i className="bx bxs-layer"></i>
                  </span>
                </div>
                <div className="row mt-4">
                  <div
                    onClick={() => navigate("/coindetail")}
                    className="col-md-8"
                  >
                    <h4>
                      Join the Binance Web3 Wallet X zkLink Nova Campaign and
                      win 1M $ZKL!
                    </h4>

                    <p className=" font-weight-normal my-3 small text-muted">
                      116.45K participants
                    </p>
                    <div className="d-flex lm089">
                      <span>
                        <img
                          src="assets/img/morph.png"
                          class="img-fluid"
                          alt="MoC"
                        />
                      </span>{" "}
                      <span>MORPHL2</span>{" "}
                      <span>
                        <img
                          src="assets/img/check.png"
                          class="img-fluid"
                          alt="MoC"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="expimg">
                      <img
                        src="assets/img/square.jpg"
                        className="img-fluid"
                        alt="Moc"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrviewList;
